import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useMatches, useLoaderData } from 'react-router-dom'
import { get } from 'lodash'

const Breadcrumbs = (props) => {
  const matches = useMatches()
  const loaderData = useLoaderData()
  const crumbs = matches
    .filter((match) => Boolean(get(match, 'handle.crumb', false)))
    .map((match) => match.handle.crumb(match.params, loaderData))

  return <Breadcrumb>{crumbs}</Breadcrumb>
}

export default Breadcrumbs
