import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Container, Button } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import UploadTransactionsTable from '../../components/financialResources/transactions/UploadTransactionsTable'
import { Link, useLoaderData, useParams } from 'react-router-dom'
import TagSchemaClassService from '../../services/tagSchemaClassService'
import { processFile } from '../../utils/transaction-file-parser'

const BankAccountTransactionsBulkVie = () => {
  const { account } = useLoaderData()
  const [state, setState] = useState({
    schemaClasses: {},
    uploadTransactions: []
  })
  useEffect(() => {
    TagSchemaClassService.getAllSchemaClasses(true).then((schemaClasses) => {
      setState((s) => ({ ...s, schemaClasses }))
    })
  }, [])
  const fileRef = useRef(null)

  const { bankAccountId } = useParams()
  const { schemaClasses, uploadTransactions } = state

  const handleProcessFile = async () => {
    if (
      !fileRef ||
      !fileRef.current ||
      !fileRef.current.files ||
      !fileRef.current.files[0]
    ) { return }

    const parsedRecords = await processFile(fileRef)
    setState({
      ...state,
      uploadTransactions: parsedRecords
    })
  }
  const onTransactionUpdate = (transaction, field, newValue) => {
    const newTransactions = uploadTransactions.map((uploadTransaction) => {
      if (uploadTransaction.uuid === transaction.uuid) {
        uploadTransaction[field] = newValue
      }
      return uploadTransaction
    })
    setState({
      ...state,
      uploadTransactions: newTransactions
    })
  }

  const onTransactionDelete = (targetTransaction) => {
    setState({
      ...state,
      uploadTransactions: uploadTransactions.filter(
        (t) => t.uuid !== targetTransaction.uuid
      )
    })
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item key='breadcrumb-accounts' linkAs='span'>
          <Link to='/financial_resources/bank_accounts'>Cuentas Bancarias</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key='breadcrumb-accounts-detail' active>
          <Link to={`/financial_resources/bank_accounts/${bankAccountId}/transactions`}>
            Cuenta {account.bank} {account.description}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key='breadcrumb-accounts-transactions' active>
          Cargar desde archivo
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Form.Group controlId='formFile' className='mb-3'>
            <Form.Label>Archivo</Form.Label>
            <Form.Control type='file' ref={fileRef} />
          </Form.Group>
          <Button type='submit' variant='primary' onClick={handleProcessFile}>
            Leer Archivo
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadTransactionsTable
            isCreditCard={account.isCreditCard}
            uploadTransactions={uploadTransactions}
            schemaClasses={schemaClasses}
            accountId={bankAccountId}
            onTransactionUpdate={onTransactionUpdate}
            onTransactionDelete={onTransactionDelete}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default BankAccountTransactionsBulkVie
