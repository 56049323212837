import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Form from 'react-bootstrap/Form'
import { capitalize } from '../../../utils/strings'
import ReactDatePicker from 'react-datepicker'

export const getFieldDate = (fieldName, entity) => {
  const dateValue = entity[fieldName]
  if (!dateValue) return new Date()
  if (dateValue.length === 10) {
    const [year, month, day] = dateValue.split('-')
    if (year && month && day) {
      return dayjs(dateValue, 'YYYY-MM-DD').toDate()
    }
  }
  if (entity[fieldName]) return new Date(dateValue)
}

const DatePicker = ({ field, disabled, entity, onChange, label }) => {
  const { name, property } = field
  const targetProp = property || name
  const fieldValue = getFieldDate(targetProp, entity)
  return (
    <>
      <Form.Label>{label || capitalize(name)}</Form.Label> <br />
      <ReactDatePicker
        disabled={disabled}
        id={`${name}-my-date-picker`}
        className='form-control'
        autoComplete='off'
        selected={fieldValue}
        onChange={(newDate) => {
          onChange(targetProp, dayjs(newDate).format('YYYY-MM-DD'))
        }}
        calendarStartDay={1}
      />
    </>
  )
}

DatePicker.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  entity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default DatePicker
