function getDefaultEntity (fields) {
  const defaultEntity = {}
  fields.forEach((field) => {
    if (field.type === 'boolean') {
      defaultEntity[field.property] = false
    } else if (field.type === 'number') {
      defaultEntity[field.property] = 0
    } else if (field.type === 'date') {
      defaultEntity[field.property] = new Date()
    } else {
      defaultEntity[field.property] = ''
    }
  })
  return defaultEntity
}

module.exports = {
  getDefaultEntity
}
