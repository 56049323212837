import { ResponsivePie } from '@nivo/pie'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getTransactionsByClass } from '../../../services/reportsService'

function TransactionsByClassPie (props) {
  const { startDate, endDate, bankAccountId, refresh, isCreditCard, negativeTransactions } = props
  const [data, setData] = useState([])
  useEffect(() => {
    setData([])
    getTransactionsByClass({
      startDate,
      endDate,
      bankAccountId,
      isCreditCard,
      negativeTransactions
    }).then(setData)
  }, [startDate, endDate, bankAccountId, isCreditCard, negativeTransactions, refresh])
  let chartLabel = 'Compras'
  if (isCreditCard === false) {
    chartLabel = negativeTransactions ? 'Retiros' : 'Depósitos'
  }
  return (
    <div className='centered'>
      <Row>
        <Col>
          <h5>{chartLabel}</h5>
        </Col>
      </Row>
      <div style={{ height: '70vh', marginBottom: '5em' }}>
        <ResponsivePie
          margin={{
            top: 40,
            right: 80,
            bottom: 80,
            left: 80
          }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          data={data}
        />
      </div>
    </div>
  )
}

TransactionsByClassPie.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  bankAccountId: PropTypes.string,
  refresh: PropTypes.number.isRequired,
  isCreditCard: PropTypes.bool,
  negativeTransactions: PropTypes.bool
}

export default TransactionsByClassPie
