import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React from 'react'
import PropTypes from 'prop-types'

const FullScreenConfirmModal = ({
  showModal,
  toggleModal,
  title,
  onConfirm,
  onCancel,
  children
}) => {
  return (
    <Modal
      centered
      size='lg'
      show={showModal}
      onHide={() => toggleModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <Button variant='danger' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='success' onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

FullScreenConfirmModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default FullScreenConfirmModal
