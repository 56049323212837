import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import ColumnHeaders from './ColumnHeaders'
import FieldColumns from './FieldColumns'
import Button from 'react-bootstrap/Button'
import { FaEdit } from 'react-icons/fa'
import OptionalComponent from '../OptionalComponent'
import EntitiesTablePagination from './EntitiesTablePagination'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import EntityTableActions from './EntityTableActions'

const EntitiesTable = (props) => {
  const {
    showEdit,
    showActions = true,
    useEdit = true,
    data,
    fields,
    extraActions = [],
    onPaginationChange,
    handleSortChange
  } = props

  let entities = []
  if (data.rows) {
    entities = data.rows
  }

  return (
    <>
      <Row>
        <Col>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr id='table-headers'>
                <ColumnHeaders fields={fields} handleSortChange={handleSortChange} />
                <OptionalComponent showComponent={showActions}>
                  <th key='header-actions' style={{ textAlign: 'center' }}>
                    Acciones
                  </th>
                </OptionalComponent>
              </tr>
            </thead>
            <tbody>
              {entities.map((entity) => {
                return (
                  <tr key={`table-row-${v4()}`}>
                    <FieldColumns fields={fields} entity={entity} />
                    <td style={{ textAlign: 'center' }}>
                      <OptionalComponent showComponent={useEdit}>
                        <Button
                          size='sm'
                          style={{ marginRight: '5px' }}
                          onClick={() => showEdit(entity)}
                        >
                          <FaEdit />
                        </Button>
                      </OptionalComponent>
                      <OptionalComponent showComponent={showActions}>
                        <EntityTableActions
                          actions={extraActions}
                          entity={entity}
                        />
                      </OptionalComponent>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <EntitiesTablePagination
            data={data}
            onPaginationChange={onPaginationChange}
          />
        </Col>
      </Row>
    </>
  )
}

EntitiesTable.propTypes = {
  showEdit: PropTypes.func,
  showDelete: PropTypes.func,
  onPaginationChange: PropTypes.func.isRequired,
  paginated: PropTypes.bool,
  showActions: PropTypes.bool,
  useEdit: PropTypes.bool,
  useDelete: PropTypes.bool,
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  extraActions: PropTypes.array,
  entityName: PropTypes.string,
  entity: PropTypes.object,
  handleSortChange: PropTypes.func.isRequired
}

export default EntitiesTable
