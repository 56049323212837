import React, { useEffect, useState } from 'react'
import { createCRUDService } from '../../services/crudService'
import { Alert, Breadcrumb } from 'react-bootstrap'
import OptionalComponent from '../../components/common/OptionalComponent'
import { Link } from 'react-router-dom'
import EntitiesList from '../../components/common/CRUD/EntitiesList'

const { listItems } = createCRUDService('/api/sales/packages')

const PackagesView = () => {
  const [state, setState] = useState({
    data: { total: 0, count: 0, rows: [] },
    paginationParams: {}
  })
  const { data, error, paginationParams } = state

  useEffect(() => {
    listItems(paginationParams)
      .then((items) => setState((s) => ({ ...s, data: items })))
      .catch((error) => setState((s) => ({ ...s, error })))
  }, [paginationParams])

  const handlePackageSelected = (selected) => {
    const packageId = selected.id
    window.location.href = `/packages/edit/${packageId}`
  }
  return (
    <main style={{ padding: '1rem 0' }}>
      <OptionalComponent showComponent={error !== undefined}>
        <Alert>{JSON.stringify(error)}</Alert>
      </OptionalComponent>
      <Link
        to='/packages/create'
        style={{ marginBottom: '1em' }}
        className='btn btn-primary'
        role='button'
      >
        Crear nuevo paquete
      </Link>
      <EntitiesList
        data={data}
        titleProp='name'
        subTitleProp='client.name'
        onClick={handlePackageSelected}
      />
    </main>
  )
}

PackagesView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-packages' active>
    Paquetes
  </Breadcrumb.Item>
)

export default PackagesView
