import React, { useState } from 'react'
import FormControl from './FormControl'
import DatePicker from './DatePicker'
import PropTypes from 'prop-types'

const Filter = (props) => {
  const { filter, onFilterChange, loaderData } = props
  const [state, setState] = useState(filter)
  const { filterBy, filterType, filterValue, render, label } = state

  const onChange = (name, value) => {
    setState({
      ...state,
      filterValue: value
    })
    onFilterChange(name, value)
  }

  const field = {
    name: filterBy,
    value: filterValue
  }
  if (filterType === 'date') {
    const entity = { [filterBy]: filterValue }
    return (
      <DatePicker
        field={field}
        onChange={onChange}
        disabled={false}
        entity={entity}
        label={label}
      />
    )
  } else if (filterType === 'custom') {
    return render(onChange, filterValue, loaderData)
  }
  return (
    <FormControl
      onChange={onChange}
      disabled={false}
      fieldValue={filterValue}
      field={field}
      title={label}
    />
  )
}

export const parseFilter = ({
  filterBy,
  targetField,
  filterType = 'text',
  operator = 'eq',
  render,
  label
}) => {
  if (!filterBy) throw new Error('No filterBy provided')
  if (!targetField) throw new Error('No targetField provided')
  return {
    filterBy,
    filterType,
    filterValue: '',
    operator,
    targetField,
    render,
    label
  }
}

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  loaderData: PropTypes.any
}

export default Filter
