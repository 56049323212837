import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import { getTransactionsByClass } from '../../../services/reportsService'

const HistoricTransactionsByClass = (props) => {
  const {
    startDate,
    endDate,
    onClassSelected,
    groupBy,
    bankAccountId,
    refresh
  } = props
  const [data, setData] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const toggleFilter = (className) => {
    const filterIndex = filterBy.indexOf(className)
    let finalFilter
    if (filterIndex === -1) {
      // if the filter has not been added we add it
      finalFilter = filterBy.concat([className])
    } else {
      // If not, we remove it!
      finalFilter = filterBy.filter((f) => f !== className)
      if (finalFilter.length === 0) onClassSelected('')
    }
    setFilterBy(finalFilter)
  }

  useEffect(() => {
    setData([])
    getTransactionsByClass({
      startDate,
      endDate,
      historic: true,
      groupBy,
      bankAccountId
    }).then((data) => {
      let finalData = data
      if (filterBy.length > 0) {
        finalData = data.map((point) => {
          if (filterBy.includes(point.id)) {
            return point
          }
          return {
            ...point,
            data: []
          }
        })
      }
      setData(finalData)
    })
  }, [startDate, endDate, filterBy, groupBy, bankAccountId, refresh])

  return (
    <div className='centered'>
      <Row>
        <Col>
          <h3>Histórico por etiqueta</h3>
        </Col>
      </Row>
      <Row className='pie-container'>
        <Col>
          <div className='centered'>
            <div style={{ height: '70vh', marginBottom: '5em' }}>
              <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: groupBy === 'week' ? 'Semana' : 'Mes',
                  legendOffset: 36,
                  legendPosition: 'middle'
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendOffset: -50,
                  legend: 'Pesos',
                  legendPosition: 'middle'
                }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'column',
                    onClick: (e) => {
                      toggleFilter(e.id)
                      onClassSelected(e.id)
                    },
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
              />
            </div>
          </div>
        </Col>
      </Row>
      {filterBy.length > 0 && (
        <Row>
          <Col xs={{ span: 3, offset: 9 }}>
            <div className='filterListLabel'>Filters: </div>
            <ul className='filterList'>
              {filterBy.map((filter, index) => (
                <li key={`filter-${index}`} className='filter'>
                  {filter}{' '}
                  <button
                    size='xs'
                    className='filterToggle'
                    onClick={() => toggleFilter(filter)}
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}
    </div>
  )
}

HistoricTransactionsByClass.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onClassSelected: PropTypes.func.isRequired,
  groupBy: PropTypes.string,
  bankAccountId: PropTypes.string,
  refresh: PropTypes.number
}

export default HistoricTransactionsByClass
