import React, { useState } from 'react'
import SchemasService from '../../services/tagSchemasService'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/system/TagSchema'
import { Breadcrumb } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'

export default function SchemasView () {
  const service = new SchemasService()
  const [state, setState] = useState({ redirect: false })
  const { redirect, redirectTo } = state
  if (redirect) {
    return <Navigate to={`/financial_resources/tag_schemas/${redirectTo}`} />
  }
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        entityName='categoría'
        entityNamePlural='categorías'
        listTitle='description'
        dataSource={() => service.getSchemas()}
        disableCreate
        onClick={(tagSchema) => {
          setState({ redirect: true, redirectTo: tagSchema.id })
        }}
      />
    </main>
  )
}

SchemasView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-schemas' active>
    Categorías
  </Breadcrumb.Item>
)
