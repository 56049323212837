import dayjs from 'dayjs'
import { getAxios } from '../utils/requests'
const axios = getAxios()

const formatDate = (date) => dayjs(date).format('YYYY-MM-DD')

export const getBalanceReport = (startDate, endDate) => {
  return axios.get('/api/financial_resources/reports/balance', {
    params: { startDate: formatDate(startDate), endDate: formatDate(endDate) }
  })
}

export const getTotalReport = ({ startDate, endDate, bankAccountId }) => {
  return axios.get('/api/financial_resources/reports/balance-overview', {
    params: { startDate: formatDate(startDate), endDate: formatDate(endDate), bankAccountId }
  })
}

export const getBalanceDetailReport = ({ startDate, endDate, bankAccountId }) => {
  return axios.get('/api/financial_resources/reports/balance-detail', {
    params: { startDate: formatDate(startDate), endDate: formatDate(endDate), bankAccountId }
  })
}

export const getFlowDistributionReport = (startDate, endDate) => {
  return axios.get('/api/financial_resources/reports/distribution', {
    params: { startDate: formatDate(startDate), endDate: formatDate(endDate) }
  })
}
const sortByYearAndMonth = (previous, current) => {
  const [previousYear, previousGroup] = previous.split('-')
  const [currentYear, currentGroup] = current.split('-')
  const previousYearInt = parseInt(previousYear)
  const previousGroupInt = parseInt(previousGroup)
  const currentYearInt = parseInt(currentYear)
  const currentGroupInt = parseInt(currentGroup)

  // If the previous year is less than current year, previous year is smaller
  if (previousYearInt < currentYearInt) return -1

  // If the year is the same, we need to see if the month is greater
  if (previousYearInt === currentYearInt) {
    // If the month is the same, both are the same
    if (previousGroupInt === currentGroupInt) return 0
    // If the previousMonth is larger than current month then previous is larger
    if (previousGroupInt > currentGroupInt) return 1
    // If previous is not equal or greater it is less than current
    return -1
  }

  // If previous year is not less or equal to current year, then a is larger
  return 1
}
const parseHistoricData = (data = []) => {
  const finalData = []
  const byClass = {}
  const dates = new Set()
  for (const point of data) {
    if (!byClass[point.name]) { byClass[point.name] = { id: point.name, dates: {} } }

    byClass[point.name].dates[point.date] = {
      x: point.date,
      y: point.value
    }

    dates.add(point.date)
  }

  for (const className in byClass) {
    const classData = byClass[className]
    const classDates = Object.keys(classData.dates)
    for (const date of dates) {
      if (classDates.includes(date)) continue
      classData.dates[date] = { x: date, y: 0 }
    }
  }

  const sortedDates = Array.from(dates).sort(sortByYearAndMonth)
  for (const className in byClass) {
    const classData = byClass[className]
    classData.data = []
    for (const targetDate of sortedDates) {
      classData.data.push(classData.dates[targetDate])
    }
    delete classData.dates
    finalData.push(classData)
  }
  return finalData
}
export const getTransactionsByClass = async ({
  startDate,
  endDate,
  historic,
  groupBy,
  bankAccountId,
  isCreditCard = true,
  negativeTransactions = false
}) => {
  const { data } = await axios.get(
    '/api/financial_resources/reports/transactions/by-class',
    {
      params: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        historic,
        groupBy,
        bankAccountId,
        isCreditCard,
        negativeTransactions
      }
    }
  )
  if (historic) {
    return parseHistoricData(data)
  }
  return data
}

export const getTagSchemaClassReport = async (
  startDate,
  endDate
) => {
  const { data } = await axios.get(
    '/api/financial_resources/reports/goals',
    {
      params: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      }
    }
  )
  return data
}
