import React from 'react'
import PropTypes from 'prop-types'

const OptionalComponent = (props) => {
  const { showComponent, children } = props
  if (showComponent) {
    return <>{children}</>
  }
  return <></>
}

OptionalComponent.propTypes = {
  showComponent: PropTypes.bool.isRequired,
  children: PropTypes.node
}

export default OptionalComponent
