import React, { useEffect, useState } from 'react'
import {
  fields,
  getCatalogueActions
} from '../../../models/sales/packageModel'
import EntitiesTable from '../../common/CRUD/EntitiesTable'
import { createCRUDService } from '../../../services/crudService'
import PropTypes from 'prop-types'
const { listItems } = createCRUDService('/api/sales/packages')

const ProjectCatalogue = (props) => {
  const [state, setState] = useState({ projects: { rows: [] } })
  const { projects } = state
  const { onPackageAdd } = props

  const onAddCallback = (item) => {
    onPackageAdd({ ...item, type: 'package' })
  }
  const extraActions = getCatalogueActions({ onAddCallback })

  useEffect(() => {
    listItems()
      .then((responseProject) =>
        setState((s) => ({ ...s, projects: responseProject }))
      )
      .catch((error) => setState((s) => ({ ...s, error })))
  }, [])

  return (
    <>
      <EntitiesTable
        paginated
        useEdit={false}
        useDelete={false}
        extraActions={extraActions}
        data={projects}
        fields={fields}
        onPaginationChange={(paginationParams) =>
          setState((s) => ({ ...s, paginationParams }))}
      />
    </>
  )
}

ProjectCatalogue.propTypes = {
  onPackageAdd: PropTypes.func.isRequired
}

export default ProjectCatalogue
