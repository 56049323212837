import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FaPrint, FaTrash } from 'react-icons/fa'
import ConfirmModal from '../../common/ConfirmModal'
import dayjs from 'dayjs'
import Table from 'react-bootstrap/Table'
import { NumericFormat } from 'react-number-format'
import { getAxios } from '../../../utils/requests'
import Accordion from 'react-bootstrap/Accordion'
import Spinner from 'react-bootstrap/Spinner'

const axios = getAxios()
const styles = {
  printButton: {
    float: 'right'
  },
  deleteButton: {
    float: 'right',
    marginRight: '.5em'
  },
  printIcon: {
    width: '12px'
  },
  header: {
    position: 'relative',
    padding: '1em'
  }
}
const QuoteListComponent = (props) => {
  const { quotes = [], handleDeleteQuote } = props
  const renderComponent = useRef(null)
  const [state, setState] = useState({
    showDeleteModal: false,
    quoteRendering: false,
    quoteRenderingId: null
  })
  const { showDeleteModal, quoteToDelete, quoteRendering, quoteRenderingId } =
    state
  const renderPDF = (quote) => {
    const quoteId = quote.id
    setState((s) => ({
      ...s,
      quoteRendering: true,
      quoteRenderingId: quoteId
    }))
    axios.get(`/api/sales/quotes/pdfQuote/${quoteId}`).then((response) => {
      setState((s) => ({
        ...s,
        quoteRendering: false,
        quoteRenderingId: null
      }))
      const { signedUrl } = response.data
      window.open(signedUrl)
    })
  }
  const toggleDeleteModal = (quote) => {
    setState((s) => {
      return {
        ...s,
        quoteToDelete: quote,
        showDeleteModal: !showDeleteModal
      }
    })
  }
  const handleDelete = async () => {
    console.log('delete quote!', quoteToDelete)
    await handleDeleteQuote(quoteToDelete)
    toggleDeleteModal()
  }
  return (
    <div ref={renderComponent}>
      {quotes.map((quote = {}) => {
        const { createdAt, total, components = [], key } = quote
        const printLoading = quoteRendering && quote.id === quoteRenderingId
        return (
          <Card key={key} style={{ textAlign: 'center' }} className='mb-3'>
            <Card.Header style={styles.header}>
              {new Date(createdAt).toLocaleDateString('es-MX')}
              <Button
                style={styles.printButton}
                onClick={() => renderPDF(quote)}
                disabled={printLoading}
              >
                {!printLoading && <FaPrint />}
                {printLoading && <Spinner animation='border' size='sm' />}
              </Button>
              <Button
                style={styles.deleteButton}
                variant='danger'
                onClick={() => toggleDeleteModal(quote)}
              >
                <FaTrash />
              </Button>
            </Card.Header>
            <Card.Body>
              Total:
              <NumericFormat
                value={total}
                prefix='$'
                displayType='text'
                thousandsGroupStyle='thousands'
                thousandSeparator=','
              />
              <br />
              <Accordion>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    Componentes: {components.length}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Concepto:</th>
                          <th>Cantidad:</th>
                          <th>Costo:</th>
                          <th>Precio:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {components.map((component, index) => {
                          return (
                            <tr key={`component-details-${index}`}>
                              <td>{component.concept}</td>
                              <td>{component.quantity}</td>
                              <td>{component.cost}</td>
                              <td>{component.price}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
            </Card.Body>
            <ConfirmModal
              show={showDeleteModal}
              title='¿Está seguro que desea borrar la siguiente cotizacion?'
              onCancel={toggleDeleteModal}
              onConfirm={handleDelete}
            >
              <div>
                <div>
                  <div>
                    Fecha:{' '}
                    {dayjs(quoteToDelete?.createdAt).format('MM-DD-YYYY')}
                  </div>
                  <div>
                    Número de Componentes: {quoteToDelete?.components?.length}
                  </div>
                  <div>Impuestos: {quoteToDelete?.tax}</div>
                  <div>Costo Total: {quoteToDelete?.totalPrice}</div>
                  <div>
                    <div>Componentes:</div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Cantidad</th>
                          <th>Descripcion</th>
                          <th>Precio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteToDelete?.components?.map((component, index) => (
                          <tr key={`component-${index}`}>
                            <td>{component.quantity}</td>
                            <td>{component.concept}</td>
                            <td>{component.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </ConfirmModal>
          </Card>
        )
      })}
    </div>
  )
}

QuoteListComponent.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.string.isRequired,
      tax: PropTypes.string.isRequired,
      totalCost: PropTypes.string.isRequired,
      totalPrice: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          concept: PropTypes.string,
          type: PropTypes.string,
          quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    })
  ),
  handleDeleteQuote: PropTypes.func.isRequired
}

export default QuoteListComponent
