import React from 'react'
import TagSchemaClassesService from '../../services/tagSchemaClassService'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/system/TagSchemaClass'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'

export default function TagSchemaClassesView () {
  const { id } = useParams()
  const service = new TagSchemaClassesService(id)
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        entityName='etiqueta'
        entityNamePlural='etiquetas'
        dataSource={() => service.getSchemaClasses()}
        onDelete={(s) => service.deleteSchemaClass(s.id)}
        onCreate={(s) => service.createSchemaClass(s)}
        onUpdate={(s) => service.updateSchemaClass(s)}
        useTable
      />
    </main>
  )
}

TagSchemaClassesView.breadcrumbs = ({ id }) => [
  <Breadcrumb.Item key='breadcrumb-schemas' linkAs='span'>
    <Link to='/financial_resources/tag_schemas'>Categorías</Link>
  </Breadcrumb.Item>,
  <Breadcrumb.Item key='breadcrumb-schema-id' active>
    {id}
  </Breadcrumb.Item>,
  <Breadcrumb.Item key='breadcrumb-classes' active>
    Etiquetas
  </Breadcrumb.Item>
]
