import React from 'react'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

const ProjectComponent = (props) => {
  const { component, onComponentEdit } = props

  const {
    concept = '',
    type = 'product',
    quantity = 0,
    cost,
    price = 0,
    key
  } = component

  const handleComponentChange = (fieldUpdated, newValue) => {
    onComponentEdit(key)
  }

  const productTypes = {
    product: 'Producto',
    service: 'Servicio',
    package: 'Paquete'
  }

  return (
    <Card
      onClick={handleComponentChange}
      className='mb-3'
      style={{ textAlign: 'center' }}
    >
      <Card.Header>
        {quantity} x {concept} ({productTypes[type]})
      </Card.Header>
      <Card.Body>
        Costo:
        <NumericFormat
          value={cost}
          prefix='$'
          displayType='text'
          thousandsGroupStyle='thousands'
          thousandSeparator=','
        />
        <br />
        Precio:
        <NumericFormat
          value={price}
          prefix='$'
          displayType='text'
          thousandsGroupStyle='thousands'
          thousandSeparator=','
        />
      </Card.Body>
    </Card>
  )
}

ProjectComponent.propTypes = {
  onComponentEdit: PropTypes.func.isRequired,
  component: PropTypes.shape({
    concept: PropTypes.string,
    type: PropTypes.string,
    key: PropTypes.string.isRequired,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
}

export default ProjectComponent
