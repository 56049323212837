import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import OptionalComponent from '../common/OptionalComponent'
import FormControl from '../common/CRUD/FormControl'
import UsersService from '../../services/usersService'
import PropTypes from 'prop-types'

const UserDetailsModal = (props) => {
  const { showModal, onCancel, initialUser } = props
  const [state, setState] = useState({
    user: {
      email: '',
      password: '',
      confirmPassword: '',
      ...initialUser
    },
    error: '',
    success: false
  })
  const { user, error, success } = state

  if (success === true) {
    setTimeout(() => {
      onCancel()
    }, 1000)
  }

  const updateUser = (field, value) =>
    setState((s) => ({ ...s, user: { ...user, [field]: value } }))
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await UsersService.updatePassword(user.password, user.newPassword)
      setState({ ...state, success: true })
    } catch (err) {
      const { response } = err
      if (response && response.data) {
        return setState({ ...state, error: response.data })
      }

      return setState({
        ...state,
        error: 'An unexpected error ocurred please contact support'
      })
    }
  }

  const passwordMissMatch = user.newPassword !== user.confirmPassword
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Modal
        show={showModal}
        aria-labelledby='contained-modal-title-vcenter'
        onHide={onCancel}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            ¡Actualice los detalles del usuario!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            field={{ name: 'email', type: 'email' }}
            fieldValue={user.email}
            disabled
          />
          <FormControl
            field={{ name: 'password', type: 'password' }}
            title='Current Password'
            fieldValue={user.password}
            onChange={(name, value) => updateUser('password', value)}
          />
          <FormControl
            field={{ name: 'newPassword', type: 'password' }}
            title='New Password'
            disabled={user.password === ''}
            fieldValue={user.newPassword}
            onChange={(name, value) => updateUser('newPassword', value)}
          />
          <FormControl
            field={{ name: 'confirmPassword', type: 'password' }}
            title='Confirm Password'
            disabled={user.password === ''}
            fieldValue={user.confirmPassword}
            onChange={(name, value) => updateUser('confirmPassword', value)}
          />
          <OptionalComponent showComponent={passwordMissMatch}>
            <Alert variant='danger'>Las contraseñas no coinciden</Alert>
          </OptionalComponent>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <OptionalComponent showComponent={error !== ''}>
              <Row>
                <Col xs={12}>
                  <Alert variant='danger'>{error}</Alert>
                </Col>
              </Row>
            </OptionalComponent>
            <OptionalComponent showComponent={success === true}>
              <Row>
                <Col xs={12}>
                  <Alert variant='success'>
                    ¡Actualización de contraseña exitosa!
                  </Alert>
                </Col>
              </Row>
            </OptionalComponent>
            <Row className='justify-content-end'>
              <Col xs={5}>
                <Button variant='danger' className='mr-3' onClick={onCancel}>
                  Cancelar
                </Button>
                <Button variant='success' type='submit' onClick={handleSubmit}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </Form>
  )
}

UserDetailsModal.propTypes = {
  initialUser: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default UserDetailsModal
