import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import AccountPicker from '../bank_accounts/BankAccountPicker'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Alert } from 'react-bootstrap'
import TransactionsService from '../../../services/transactionsService'
import TagSchemaClassPicker from './TagSchemaClassPicker'
import dayjs from 'dayjs'

const QuickTransfer = (props) => {
  const { handleTransactionTypeUpdate, transactionType, onSave } = props
  const [state, setState] = useState({ amount: 0, className: '' })
  const {
    sourceBankAccountId,
    destinationBankAccountId,
    amount,
    showAlert = false,
    alertVariant,
    alertMessage,
    description,
    className
  } = state
  const updateState = (values) => setState((s) => ({ ...s, ...values }))

  const handleTransferUpdate = ({ target }) => {
    const prop = target.id
    updateState({
      [prop]: target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (sourceBankAccountId === destinationBankAccountId) {
      return updateState({
        alertMessage:
          'No puede seleccionar la misma cuenta como origen y destino',
        showAlert: true,
        alertVariant: 'danger'
      })
    }
    if (amount <= 0) {
      return updateState({
        alertMessage:
          'Las transferencias deben de ser números positivos mayores a 0',
        showAlert: true,
        alertVariant: 'danger'
      })
    }
    const scheduleClose = () => setTimeout(onSave, 1000)
    const service = new TransactionsService(sourceBankAccountId)
    return service
      .createTransfer({
        sourceBankAccountId,
        destinationBankAccountId,
        amount,
        description,
        date: dayjs().format('YYYY-MM-DD')
      })
      .then(() => {
        updateState({
          alertMessage: 'Transferencia creada exitosamente',
          showAlert: true,
          alertVariant: 'success'
        })
        scheduleClose()
      })
      .catch((error) => {
        return updateState({
          alertMessage: `Error al crear transferencia (${error?.response?.data})`,
          showAlert: true,
          alertVariant: 'danger'
        })
      })
  }

  return (
    <>
      <Modal
        show
        onHide={onSave}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>Transferencia de fondos</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>}
            <Form.Control
              as='select'
              value={transactionType}
              onChange={handleTransactionTypeUpdate}
              required
            >
              <option value='transfer'>Transferencia</option>
              <option value='positive'>Depósito</option>
              <option value='negative'>Retiro</option>
            </Form.Control>
            <Form.Group controlId='sourceBankAccountId'>
              <Form.Label>Cuenta Origen</Form.Label>
              <AccountPicker
                isCreditCard={false}
                onUpdate={(selectedAccount) =>
                  handleTransferUpdate({
                    target: {
                      id: 'sourceBankAccountId',
                      value: selectedAccount
                    }
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId='destinationBankAccountId'>
              <Form.Label>Cuenta Destino</Form.Label>
              <AccountPicker
                isCreditCard={false}
                onUpdate={(selectedAccount) =>
                  handleTransferUpdate({
                    target: {
                      id: 'destinationBankAccountId',
                      value: selectedAccount
                    }
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId='amount'>
              <Form.Label>Monto</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  required
                  value={amount}
                  step='.01'
                  onChange={handleTransferUpdate}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId='description'>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type='text'
                value={description}
                required
                onChange={handleTransferUpdate}
              />
            </Form.Group>
            <Form.Group controlId='className'>
              <Form.Label>Etiqueta</Form.Label>
              <TagSchemaClassPicker
                transaction={{ className }}
                creditClasses={false}
                required={false}
                onTransactionUpdate={(transaction, field, newValue) => {
                  return handleTransferUpdate({
                    target: {
                      id: 'className',
                      value: newValue
                    }
                  })
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={onSave}>
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Confirmar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

QuickTransfer.propTypes = {
  isCreditCard: PropTypes.bool,
  handleTransactionTypeUpdate: PropTypes.func,
  onSave: PropTypes.func,
  toggleModal: PropTypes.func,
  transactionType: PropTypes.string
}

export default QuickTransfer
