import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link, useParams, useLoaderData } from 'react-router-dom'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields, filters } from '../../models/financialResources/bankAccountTransaction'
import { createCRUDService } from '../../services/crudService'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import QuickTransaction from '../../components/financialResources/transactions/QuickTransaction'

const BankAccountsTransactionsModal = (params) => {
  const { toggleModal, onSave, transaction } = params
  return (
    <QuickTransaction
      showQuickTransaction
      toggleModal={toggleModal}
      onSave={onSave}
      transaction={transaction}
    />
  )
}

BankAccountsTransactionsModal.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.number,
    description: PropTypes.number,
    amount: PropTypes.number,
    className: PropTypes.string
  })
}

const BankAccountsTransactionsView = () => {
  const loaderData = useLoaderData()
  const params = useParams()
  const { bankAccountId } = params
  const { listItems, deleteItem, updateItem } = createCRUDService(
    `/api/financial_resources/bank_accounts/${bankAccountId}/transactions`
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <Link
        to={`/financial_resources/bank_accounts/${bankAccountId}/transactions/bulk`}
        className='float-right'
      >
        <Button variant='success'>Cargar desde archivo</Button>
      </Link>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <CRUD
        fields={fields}
        filters={filters}
        paginated
        entityName='Transacción bancaria'
        entityNamePlural='Transacciones bancarias'
        dataSource={listItems}
        onDelete={deleteItem}
        onUpdate={updateItem}
        loaderData={loaderData}
        customModal={({
          action,
          entity,
          loaderData,
          onCreateOrUpdateHandler,
          onDeleteHandler,
          onCancelHandler
        }) => {
          const { account } = loaderData
          if (!action) return <></>
          return (
            <QuickTransaction
              showQuickTransaction
              toggleModal={onCancelHandler}
              onSave={onCreateOrUpdateHandler}
              onDeleteHandler={onDeleteHandler}
              transaction={entity}
              update={action === 'update'}
              isCreditCard={account.isCreditCard}
              bankAccountId={account.id}
            />
          )
        }}
        showAllInList
        useTable
      />
    </main>
  )
}

BankAccountsTransactionsView.breadcrumbs = ({ bankAccountId }) => {
  return [
    <Breadcrumb.Item key='breadcrumb-accounts' linkAs='span'>
      <Link to='/financial_resources/bank_accounts'>Cuentas Bancarias</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-accounts-detail' active>
      Cuenta {bankAccountId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-accounts-transactions' active>
      Transacciones bancarias
    </Breadcrumb.Item>
  ]
}

export default BankAccountsTransactionsView
