import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Breadcrumb, FormControl, InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FullScreenConfirmModal from '../../components/common/FullScreenConfirmModal'
import { FaSearch, FaTrash, FaEdit } from 'react-icons/fa'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { v4 as uuidv4 } from 'uuid'
import { useFocus } from '../../utils/hooks'
import { findItem, sendInventoryUpdate } from '../../services/itemsService'

const styles = {
  itemAction: {
    margin: '0 .3em'
  },
  itemsTable: {
    marginTop: '2em'
  },
  smallCol: {
    width: '10%',
    textAlign: 'center'
  },
  medCol: {
    width: '20%',
    textAlign: 'center'
  }
}

const defaultState = {
  entries: [],
  entryToAdd: { name: '', quantity: '' },
  itemFound: false,
  itemToSearch: '',
  searchError: false,
  isUpdate: false,
  showConfirmModal: false
}

const InventoryInView = () => {
  const { companyId, inventoryId } = useParams()
  const [state, setState] = useState(defaultState)
  const {
    entries,
    entryToAdd,
    itemToSearch,
    searchError,
    isUpdate,
    showConfirmModal
  } = state
  const itemsToAdd = entries.length
  const [itemQuantityRef, setQuantityFocus] = useFocus()
  const [itemCodeRef, setItemCodeFocus] = useFocus()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!entryToAdd.quantity) return
    if (isUpdate) {
      const updatedEntry = entryToAdd
      const newEntries = entries.map((entry) => {
        if (entry.uuid === updatedEntry.uuid) return updatedEntry
        return entry
      })
      setState({
        ...defaultState,
        entries: newEntries
      })
    } else {
      entryToAdd.uuid = uuidv4()
      setState({
        ...defaultState,
        entries: entries.concat(entryToAdd)
      })
    }
    setItemCodeFocus()
  }
  const itemToAddSearchCode = (event) => {
    const {
      target: { value }
    } = event
    setState({
      ...state,
      itemToSearch: value
    })
  }
  const searchItem = async (e) => {
    e.preventDefault()
    if (!itemToSearch) return
    const itemFound = await findItem(itemToSearch)
    if (itemFound) {
      setState({
        ...state,
        entryToAdd: itemFound,
        searchError: false
      })
      setQuantityFocus()
    } else {
      setState({
        ...state,
        searchError: true
      })
    }
  }
  const itemToAddQuantity = (event) => {
    const {
      target: { value }
    } = event
    setState({ ...state, entryToAdd: { ...entryToAdd, quantity: value } })
  }
  const removeItem = (entry) => {
    const newEntries = entries.filter((e) => e !== entry)
    setState({ ...state, entries: newEntries })
  }
  const updateItem = (entry) => {
    setState({ ...state, entryToAdd: entry, isUpdate: true })
    setQuantityFocus()
  }
  const sendEntries = async () => {
    try {
      const parsedEntries = entries.map((entry) => ({
        ...entry,
        id: undefined,
        itemId: entry.id
      }))
      const result = await sendInventoryUpdate(
        companyId,
        inventoryId,
        parsedEntries
      )
      console.log(result)
      window.location.href = `/companies/${companyId}/inventories/${inventoryId}/stock`
    } catch (ex) {
      alert(ex)
    }
  }
  const setShowConfirmModal = (showModal) => {
    setState({ ...state, showConfirmModal: showModal })
  }

  return (
    <div>
      <FullScreenConfirmModal
        showModal={showConfirmModal}
        toggleModal={setShowConfirmModal}
        footer='hello!'
        onConfirm={sendEntries}
        onCancel={() => setShowConfirmModal(false)}
      >
        <p>Por favor, confirme las siguientes transacciones:</p>
        <ul>
          {entries.map((entry) => (
            <li key={`confirm-li-${entry.uuid}`}>
              Nombre: {entry.name}, Cantidad: {entry.quantity}
            </li>
          ))}
        </ul>
      </FullScreenConfirmModal>
      <Form onSubmit={searchItem}>
        <Row>
          <Col>
            <InputGroup className='mb-3'>
              <FormControl
                placeholder='Enter the item code'
                aria-label='Enter the item code'
                aria-describedby='basic-addon2'
                onChange={itemToAddSearchCode}
                value={itemToSearch}
                ref={itemCodeRef}
              />
              <InputGroup.Append>
                <Button variant='outline-secondary' type='submit'>
                  <FaSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {searchError && (
              <Alert variant='danger'>
                Artículo no encontrado, ¿Quieres agregarlo?{' '}
                <a href='/items' target='_blank'>
                  ¿Quieres agregarlo?
                </a>
              </Alert>
            )}
          </Col>
        </Row>
      </Form>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId='itemName'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type='text' value={entryToAdd.name} readOnly />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='itemQuantity'>
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type='number'
                value={entryToAdd.quantity}
                ref={itemQuantityRef}
                onChange={itemToAddQuantity}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant='secondary'
              type='submit'
              style={{ width: '100%', padding: '1em' }}
            >
              {isUpdate ? 'Update Item' : 'Add to list'}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <h3>Elementos para agregar:</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover style={styles.itemsTable}>
            <thead>
              <tr>
                <th style={styles.smallCol}>Identificación del artículo</th>
                <th>Nombre del árticulo</th>
                <th style={styles.smallCol}>Cantidad</th>
                <th style={styles.medCol}>Comportamiento</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => (
                <tr key={`items-to-add-${index}`}>
                  <td style={styles.smallCol}>{entry.id}</td>
                  <td>{entry.name}</td>
                  <td style={styles.smallCol}>{entry.quantity}</td>
                  <td style={styles.medCol}>
                    <Button
                      variant='danger'
                      style={styles.itemAction}
                      onClick={() => removeItem(entry)}
                    >
                      <FaTrash />
                    </Button>
                    <Button
                      variant='secondary'
                      style={styles.itemAction}
                      onClick={() => updateItem(entry)}
                    >
                      <FaEdit />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant='primary'
            type='submit'
            className='float-right'
            disabled={itemsToAdd <= 0}
            style={{ width: '100%', padding: '1em' }}
            onClick={() => setShowConfirmModal(true)}
          >
            Complete entrada
          </Button>
        </Col>
      </Row>
    </div>
  )
}

InventoryInView.breadcrumbs = (params) => {
  const { companyId, inventoryId } = params
  return [
    <Breadcrumb.Item key='breadcrumb-companies' href='/companies'>
      Compañias
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-company-id' active>
      {companyId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item
      key='breadcrumb-inventories'
      href={`/companies/${companyId}/inventories`}
    >
      Inventarios
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventory-id' active>
      {inventoryId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item
      key='breadcrumb-inventories-stock'
      href={`/companies/${companyId}/inventories/${inventoryId}/stock`}
    >
      Existencias
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventories-in' active>
      Entradas
    </Breadcrumb.Item>
  ]
}

export default InventoryInView
