import React, { useState, useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LoginView from './views/system/LoginView'
import { getToken } from './utils/session'
import routes from './routes'
import { getSessionInfo } from './services/session'
import AppContext from './AppContext'
import { get } from 'lodash'

const MainApp = () => {
  const [state, setState] = useState({})
  const { user, userRoutes, userModules, error } = state

  useEffect(() => {
    // No catch needed because we have that in an Axios interceptor
    getSessionInfo()
      .then((response) => {
        const { data } = response
        const { user, modules } = data
        const mainRoute = routes[0]
        mainRoute.children = mainRoute.children.filter((childRoute) => {
          const [module, submodule] = childRoute.path.split('/')
          if (module === 'system') return true
          const hasModule = get(modules, module, false)
          const hasSubmodule = get(
            modules,
            `${module}.submodules.${submodule}`,
            false
          )
          const keepRoute = hasModule !== false && hasSubmodule !== false
          return keepRoute
        })
        return setState((s) => ({
          ...s,
          user: {
            ...user,
            password: '',
            confirmPassword: '',
            newPassword: ''
          },
          userRoutes: [mainRoute],
          userModules: modules
        }))
      })
      .catch((err) => {
        setState((s) => ({ ...s, error: err }))
      })
  }, [])
  if (!user) {
    // @TODO render loading screen
    return (
      <div>
        <h1>Loading!</h1>
      </div>
    )
  }
  if (error) {
    // @TODO render error screen
  }
  const router = createBrowserRouter(userRoutes)
  // @TODO filter routes using components in user response
  return (
    <AppContext.Provider value={{ user, userModules }}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  )
}

const App = () => {
  const token = getToken()
  if (!token) return <LoginView />
  return <MainApp />
}

export default App
