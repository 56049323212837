function getToken () {
  return localStorage.getItem('token')
}

function setToken (token = '') {
  return localStorage.setItem('token', token)
}

module.exports = {
  getToken,
  setToken
}
