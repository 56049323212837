import React from 'react'
import SuppliersView from '../views/financialResources/SuppliersView'
import DebitReportView from '../views/financialResources/DebitReportView'
import CreditReportView from '../views/financialResources/CreditReportView'
import BankAccountsView from '../views/financialResources/BankAccountsView'
import TagSchemaClassGoalsView from '../views/financialResources/TagSchemaClassGoalsView'
import BankAccountsTransactionsView from '../views/financialResources/BankAccountsTransactionsView'
import BanckAccountTransactionsBulkView from '../views/financialResources/BanckAccountTransactionsBulkView'
import TagSchemasView from '../views/financialResources/TagSchemasView'
import TagSchemaClassesView from '../views/financialResources/TagSchemaClassesView'
import { createCRUDService } from '../services/crudService'
import ReportView from '../views/financialResources/ReportView'

const routes = [
  {
    path: 'financial_resources/suppliers',
    element: <SuppliersView />,
    handle: {
      crumb: SuppliersView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/reports',
    element: <ReportView />,
    handle: {
      crumb: ReportView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/reports/credit',
    element: <CreditReportView />,
    handle: {
      crumb: CreditReportView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/reports/debit',
    element: <DebitReportView />,
    handle: {
      crumb: DebitReportView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/bank_accounts',
    element: <BankAccountsView />,
    handle: {
      crumb: BankAccountsView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/bank_accounts/:bankAccountId/transactions',
    element: <BankAccountsTransactionsView />,
    loader: async ({ params }) => {
      const { getItem } = createCRUDService(
        '/api/financial_resources/bank_accounts'
      )
      const { bankAccountId } = params
      const account = await getItem(bankAccountId)
      return {
        account
      }
    },
    handle: {
      crumb: BankAccountsTransactionsView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/bank_accounts/:bankAccountId/transactions/bulk',
    element: <BanckAccountTransactionsBulkView />,
    handle: {
      crumb: BanckAccountTransactionsBulkView.breadcrumbs
    },
    loader: async ({ params }) => {
      const { getItem } = createCRUDService(
        '/api/financial_resources/bank_accounts'
      )
      const { bankAccountId } = params
      const account = await getItem(bankAccountId)
      return {
        account
      }
    }
  },
  {
    path: 'financial_resources/tag_schemas',
    element: <TagSchemasView />,
    handle: {
      crumb: TagSchemasView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/tag_schemas/:id',
    element: <TagSchemaClassesView />,
    handle: {
      crumb: TagSchemaClassesView.breadcrumbs
    }
  },
  {
    path: 'financial_resources/goals',
    element: <TagSchemaClassGoalsView />,
    handle: {
      crumb: TagSchemaClassGoalsView.breadcrumbs
    }
  }
]

export default routes
