import { parseField } from '../../components/common/CRUD/Field'
import { get } from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import TagSchemaClassPicker from '../../components/financialResources/transactions/TagSchemaClassPicker'
import React from 'react'

export const fields = [
  parseField({
    name: 'ID',
    property: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Periodicidad',
    property: 'ratio',
    type: 'select',
    options: [
      {
        label: 'Semana',
        value: 'week'
      },
      {
        label: 'Mes',
        value: 'month'
      }
    ]
  }),
  parseField({
    name: 'Inicio',
    property: 'start',
    type: 'date'
  }),
  parseField({
    name: 'Final',
    property: 'end',
    type: 'date'
  }),
  parseField({
    name: 'Cantidad',
    property: 'amount'
  }),
  parseField({
    name: 'Categoría',
    type: 'custom',
    render (entity) {
      return entity.class.name
    },
    renderEdit ({ entity, onChange }) {
      const className = get(entity, 'className', get(entity, 'class.name', ''))
      const transaction = {
        ...entity,
        className
      }
      const isUpdate = entity.id !== undefined
      return (
        <Row key='class-picker'>
          <Col>
            <Form.Label>Categoría</Form.Label>
            <TagSchemaClassPicker
              disabled={isUpdate}
              transaction={transaction}
              creditClasses
              onTransactionUpdate={(transaction, field, newValue) => {
                onChange('className', newValue)
              }}
            />
          </Col>
        </Row>
      )
    }
  })
]
