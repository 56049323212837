import React from 'react'
import FormControl from './FormControl'
import DatePicker from './DatePicker'
import Select from './Select'
import Form from 'react-bootstrap/Form'
import { capitalize } from '../../../utils/strings'

function getIsDisabled ({ insertDisabled, editDisabled }, isCreate) {
  if (isCreate) {
    return insertDisabled === true
  } else {
    return editDisabled === true
  }
}

const getFieldValue = (fieldName, entity) => {
  return entity[fieldName]
}

const typeFactory = {
  date: ({ field, entity, onChange, disabled }) => {
    return (
      <DatePicker
        field={field}
        disabled={disabled}
        entity={entity}
        onChange={onChange}
      />
    )
  },
  custom: (fieldData) => {
    const { field, disabled } = fieldData
    return field.renderEdit(fieldData, disabled)
  },
  select: ({ field, onChange, fieldValue, disabled }) => {
    return (
      <Select
        onChange={onChange}
        disabled={disabled}
        defaultValue={fieldValue}
        options={field.options}
        fieldName={field.name}
        property={field.property}
        noEmptyValue
      />
    )
  },
  boolean: (fieldData) => {
    const { field, disabled, fieldValue, onChange, title } = fieldData
    const { name, property } = field
    const targetProperty = property || name
    return (
      <>
        <Form.Group className='mb-1' controlId={targetProperty}>
          <Form.Label>{title || capitalize(name)}</Form.Label>
          <Form.Check
            disabled={disabled}
            type='switch'
            checked={fieldValue}
            onChange={(event) => {
              const { currentTarget: { checked } } = event
              onChange(targetProperty, checked)
            }}
          />
        </Form.Group>
      </>
    )
  }
}

export const getFieldControl = (fieldData) => {
  const {
    field,
    isCreate,
    entity,
    readOnly = false,
    onChange,
    autoFocus = false,
    loaderData
  } = fieldData
  const { property, type } = field
  const disabled = readOnly === true || getIsDisabled(field, isCreate)
  const fieldValue = getFieldValue(property, entity)

  if (typeFactory[type]) {
    return typeFactory[type]({ ...fieldData, disabled, fieldValue, loaderData })
  }

  return (
    <FormControl
      field={field}
      onChange={onChange}
      disabled={disabled}
      fieldValue={fieldValue}
      autoFocus={autoFocus}
    />
  )
}
