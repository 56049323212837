import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'
import { NumericFormat } from 'react-number-format'
import React from 'react'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Número',
    property: 'number',
    type: 'number'
  }),
  parseField({
    name: 'Banco',
    property: 'bank'
  }),
  parseField({
    name: 'Descripción',
    property: 'description'
  }),
  parseField({
    name: 'Balance Inicial',
    property: 'initialBalance',
    type: 'number',
    formatter: (amount) => (
      <NumericFormat
        value={amount}
        prefix='$'
        displayType='text'
        thousandsGroupStyle='thousands'
        thousandSeparator=','
      />
    )
  }),
  parseField({
    name: 'Límite de crédito',
    property: 'creditLimit',
    type: 'number',
    formatter: (amount) => (
      <NumericFormat
        value={amount}
        prefix='$'
        displayType='text'
        thousandsGroupStyle='thousands'
        thousandSeparator=','
      />
    )
  }),
  parseField({
    name: 'Crédito',
    property: 'isCreditCard',
    type: 'boolean',
    defaultValue: false
  })
]

export const extraActions = [
  parseFieldAction(
    (entity, redirectTo) => {
      redirectTo(`/financial_resources/bank_accounts/${entity.id}/transactions`)
    },
    'FaMoneyBillWaveAlt',
    'Transacciones'
  )
]
