import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/sales/clientModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const ClientsView = () => {
  const { listItems, deleteItem, createItem, updateItem } =
    createCRUDService('/api/sales/clients')
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='Cliente'
        entityNamePlural='Clientes'
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

ClientsView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-clients' href='/clients' active>
    Clientes
  </Breadcrumb.Item>
)

export default ClientsView
