import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ClientPicker from '../../common/ClientPicker'
import EditableField from '../../common/EditableField'
import Container from 'react-bootstrap/Container'

const ProjectDetails = (props) => {
  const {
    id,
    name,
    totalHours,
    clientId,
    handleProjectUpdate,
    disableClientPicker = true
  } = props
  return (
    <Container>
      <Row>
        <Col>
          <h3>Detalles</h3>
        </Col>
      </Row>
      <Row>
        {/** project.id **/}
        <Col md={12} lg={2}>
          <Form.Group className='mb-3'>
            <Form.Label>ID</Form.Label>
            <Form.Control type='number' value={id} readOnly />
          </Form.Group>
        </Col>
        {/** project.client **/}
        <Col md={12} lg={4}>
          <Form.Group className='mb-3'>
            <Form.Label>Cliente</Form.Label>
            <ClientPicker
              disabled={disableClientPicker}
              initialClientId={clientId}
              onClientUpdate={(clientId) =>
                handleProjectUpdate('clientId', clientId)}
            />
          </Form.Group>
        </Col>
        {/** project.name **/}
        <Col md={12} lg={4}>
          <EditableField
            fieldType='text'
            fieldName='name'
            config={{
              defaultValue: name || '',
              fieldLabel: 'Nombre del projecto'
            }}
            onChange={handleProjectUpdate}
          />
        </Col>
        <Col md={12} lg={2}>
          <EditableField
            fieldType='number'
            fieldName='totalHours'
            config={{
              defaultValue: totalHours || 0,
              fieldLabel: 'Horas totales'
            }}
            onChange={handleProjectUpdate}
          />
        </Col>
      </Row>
    </Container>
  )
}

ProjectDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  totalHours: PropTypes.number,
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleProjectUpdate: PropTypes.func.isRequired,
  disableClientPicker: PropTypes.bool
}

export default ProjectDetails
