import React, { useEffect, useState } from 'react'
import { Alert, Breadcrumb } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { get } from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductsAndServicesModal from '../../components/sales/ProductsAndServicesModal'
import Button from 'react-bootstrap/Button'
import OptionalComponent from '../../components/common/OptionalComponent'
import PackagesDetails from '../../components/sales/packages/PackagesDetails'
import { createCRUDService } from '../../services/crudService'
import AlertMessage from '../../components/system/AlertMessage'
import { v4 } from 'uuid'

const { createItem, getItem, updateItem, deleteItem } = createCRUDService(
  '/api/sales/packages'
)

const PackageManagementView = () => {
  const [state, setState] = useState({
    catalogueIsOpen: false,
    myPackage: {
      id: '',
      name: '',
      code: '',
      description: '',
      taxCode: '',
      components: []
    },
    alerts: []
  })
  const { catalogueIsOpen, myPackage, alerts } = state
  const { action, id } = useParams()
  const isCreation = id === undefined
  const isDelete = action === 'delete'

  useEffect(() => {
    if (!isCreation) {
      getItem(id).then((data) => {
        if (!data.components) data.components = []
        setState((s) => ({ ...s, myPackage: data }))
      })
    }
  }, [id, isCreation])

  const showCatalogue = () => setState({ ...state, catalogueIsOpen: true })
  const hideCatalogue = () => setState({ ...state, catalogueIsOpen: false })
  const handlePackageUpdate = (field, newValue) => {
    myPackage[field] = newValue
    setState((s) => ({ ...s, myPackage }))
  }
  const handleError = (error) => {
    const errorData = get(error, 'response.data')
    if (errorData) {
      return setState({
        ...state,
        alerts: [{ level: 'error', message: JSON.stringify(errorData) }]
      })
    }
    setState({ ...state, error: 'Unexpected error' })
  }
  const handleComponentsUpdate = (selectedComponents = []) => {
    const parsedComponents = selectedComponents.map((component) => {
      return {
        ...component,
        componentId: component.id,
        id: undefined
      }
    })
    myPackage.components = myPackage.components.concat(parsedComponents)
    setState((s) => ({
      ...s,
      myPackage,
      catalogueIsOpen: false,
      alerts: [{ level: 'success', message: 'Guardado' }]
    }))
  }
  const handleDelete = () => {
    deleteItem(myPackage)
      .then(() => (window.location = '/packages'))
      .catch(handleError)
  }
  const handleSave = () => {
    if (isDelete) return handleDelete()
    const targetMethod = isCreation ? createItem : updateItem
    targetMethod(myPackage)
      .then((result) => {
        if (isCreation) {
          const packageId = result.data.id
          if (packageId) { return (window.location = `/packages/edit/${packageId}`) }
          return setState({
            ...state,
            alerts: [
              { level: 'error', message: '¡Error al guardar el paquete!' }
            ]
          })
        }
        setState({
          ...state,
          alerts: [{ level: 'success', message: 'Guardado' }]
        })
      })
      .catch(handleError)
  }

  const handleComponentRemove = (index) => {
    myPackage.components.splice(index, 1)
    setState({ ...state, myPackage })
  }

  const saveVariant = isDelete ? 'danger' : 'primary'
  const saveText = isDelete ? 'DELETE' : 'Guardar'

  return (
    <Container>
      {alerts.map(({ level, message }) => {
        return <AlertMessage key={v4()} level={level} message={message} />
      })}
      <OptionalComponent showComponent={!catalogueIsOpen}>
        <Row className='mb-3'>
          <Col>
            <PackagesDetails
              readOnly={isDelete}
              targetPackage={myPackage}
              onComponentRemoved={handleComponentRemove}
              onPackageUpdated={handlePackageUpdate}
              onAddClick={showCatalogue}
            />
          </Col>
        </Row>
        <OptionalComponent showComponent={isDelete}>
          <Row>
            <Col>
              <Alert variant='danger'>
                ¡Advertencia, esta acción no se puede deshacer!
              </Alert>
            </Col>
          </Row>
        </OptionalComponent>
        <Row>
          <Col className='d-flex justify-content-end mb-5'>
            <Link to='/packages' className='btn btn-secondary'>
              Cancelar
            </Link>
          </Col>
          <Col>
            <Button variant={saveVariant} onClick={handleSave}>
              {saveText}
            </Button>
          </Col>
        </Row>
      </OptionalComponent>
      <OptionalComponent showComponent={catalogueIsOpen}>
        <ProductsAndServicesModal
          onClose={hideCatalogue}
          onSave={handleComponentsUpdate}
        />
      </OptionalComponent>
    </Container>
  )
}

PackageManagementView.breadcrumbs = () => [
  <Breadcrumb.Item key='breadcrumb-packages' as='span' linkAs='span'>
    <Link to='/packages'>Paquetes</Link>
  </Breadcrumb.Item>,
  <Breadcrumb.Item key='breadcrumb-packages-creation' active>
    Gestión de paquetes
  </Breadcrumb.Item>
]

export default PackageManagementView
