import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ReportView = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Link to='/financial_resources/reports/credit'>
            <Button variant='primary' size='lg'>Reporte de Crédito</Button>
          </Link>
        </Col>
        <Col>
          <Link to='/financial_resources/reports/debit'>
            <Button variant='primary' size='lg'>Reporte de Débito</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

ReportView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-reports' active>
    Reportes
  </Breadcrumb.Item>
)

export default ReportView
