import React from 'react'
import { setToken } from '../../utils/session'

const LogoutView = () => {
  setToken('')
  window.location.href = '/'
  return <></>
}

export default LogoutView
