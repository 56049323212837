import { Breadcrumb } from 'react-bootstrap'
import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/restaurants/menusModel'
import { createCRUDService } from '../../services/crudService'

const { listItems, createItem } = createCRUDService('/api/restaurants/menus')

const MenusView = () => {
  const onClick = (item) => {
    window.location.href = `/restaurants/menus/${item.id}/items`
  }
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        entityName='menú'
        listTitle='id'
        showAllInList
        dataSource={listItems}
        onCreate={createItem}
        onClick={onClick}
      />
    </main>
  )
}

MenusView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-home' href='/' active>
    Menús
  </Breadcrumb.Item>
)

export default MenusView
