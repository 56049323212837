import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import QuoteComponents from './QuoteComponents'

const ProjectQuoteModal = (props) => {
  const { show, components, onQuoteSave, onCancel, onNewQuoteComponentRemoved } = props
  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Nueva Cotización
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>¿Desea crear la siguiente cotización?</h4>
          <QuoteComponents components={components} onNewQuoteComponentRemoved={onNewQuoteComponentRemoved} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onQuoteSave} variant='primary'>
            Guardar
          </Button>
          <Button onClick={onCancel} variant='secondary'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

ProjectQuoteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      concept: PropTypes.string,
      type: PropTypes.string,
      key: PropTypes.string.isRequired,
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  onQuoteSave: PropTypes.func.isRequired,
  onNewQuoteComponentRemoved: PropTypes.func.isRequired
}

export default ProjectQuoteModal
