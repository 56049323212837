import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

const MenuDetails = (props) => {
  const { menu, onChange } = props
  if (!menu) return <div>Cargando...</div>
  console.log(menu)
  const handleMenuUpdate = ({ target }) => {
    const prop = target.id
    onChange(prop, target.value)
  }
  return (
    <Container>
      <Row>
        {/** project.id **/}
        <Col md={12} lg={2}>
          <Form.Group className='mb-3'>
            <Form.Label>ID</Form.Label>
            <Form.Control type='number' value={menu.id} readOnly />
          </Form.Group>
        </Col>
        <Col md={12} lg={10}>
          <Form.Group className='mb-3'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              id='name'
              minLength='4'
              maxLength='30'
              value={menu.name}
              onChange={handleMenuUpdate}
            />
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className='mb-3'>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              id='description'
              value={menu.description}
              onChange={handleMenuUpdate}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  )
}

MenuDetails.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
}

export default MenuDetails
