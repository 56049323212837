import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  })
]

export const extraActions = [
  parseFieldAction(
    (entity) => {
      window.location.href = `/companies/${entity.id}/inventories`
    },
    'FaArchive',
    'Inventarios'
  )
]
