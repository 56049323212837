import React from 'react'
import { createCRUDService } from '../../services/crudService'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/admin/userModel'
import { Breadcrumb } from 'react-bootstrap'

const { listItems, deleteItem, createItem, updateItem } =
  createCRUDService('/api/admin/users')

const UsersView = () => {
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        entityName='usuario'
        listTitle='email'
        paginated
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

UsersView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-users' href='/users' active>
    Usuarios
  </Breadcrumb.Item>
)

export default UsersView
