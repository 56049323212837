import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'ID Proyecto',
    property: 'projectId',
    editDisabled: true
  }),
  parseField({
    name: 'Costo Total',
    property: 'totalCost'
  }),
  parseField({
    name: 'Precio Total',
    property: 'totalPrice'
  }),
  parseField({
    name: 'Impuestos',
    property: 'tax'
  }),
  parseField({
    name: 'Total',
    property: 'total'
  })
]
