import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'ID',
    property: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  parseField({
    name: 'Descripción',
    property: 'description'
  })
]
