import TransactionsService from '../../../services/transactionsService'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import React, { useState } from 'react'
import TagSchemaClassPicker from './TagSchemaClassPicker'
import { v4 as uuid } from 'uuid'
import { FaTrash } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
const UploadTransactionsTable = (props) => {
  const {
    uploadTransactions,
    schemaClasses,
    onTransactionUpdate,
    onTransactionDelete,
    accountId,
    isCreditCard = false
  } = props
  const [state, setState] = useState({ redirect: false })
  const { redirect } = state

  if (redirect) {
    return <Navigate to={`/financial_resources/bank_accounts/${accountId}/transactions`} />
  }

  const service = new TransactionsService(accountId)
  if (uploadTransactions.length < 1) {
    return <span />
  }
  const handleUpload = async () => {
    try {
      await service.createTransactions(uploadTransactions)
      setState({ redirect: true })
    } catch (err) {
      const errorMessage = 'Error uploading transactions'
      console.log(errorMessage)
      console.log(err)
      alert(errorMessage)
    }
  }

  return (
    <div>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Descripción</th>
            <th>Monto</th>
            <th>Etiqueta</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {uploadTransactions.map((transaction) => {
            return (
              <tr key={`transaction-uploader-${uuid()}`}>
                <td>{transaction.date}</td>
                <td>{transaction.description}</td>
                <td>{transaction.amount}</td>
                <td>
                  <TagSchemaClassPicker
                    key={`transaction-schema-picker-${uuid()}`}
                    creditClasses={isCreditCard}
                    transaction={transaction}
                    onTransactionUpdate={onTransactionUpdate}
                    initialSchemaClasses={schemaClasses}
                  />
                </td>
                <td>
                  <Button
                    variant='danger'
                    className='ml-4'
                    onClick={() => onTransactionDelete(transaction)}
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Button onClick={handleUpload}>Cargar Transacciones</Button>
    </div>
  )
}

UploadTransactionsTable.propTypes = {
  uploadTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      amount: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  schemaClasses: PropTypes.any,
  onTransactionUpdate: PropTypes.func.isRequired,
  onTransactionDelete: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  isCreditCard: PropTypes.bool
}

export default UploadTransactionsTable
