import React from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { FaTrash } from 'react-icons/fa'
import { Decimal } from 'decimal.js'
import PropTypes from 'prop-types'

const styles = {
  centerText: { textAlign: 'center' }
}

const toFixed = (x) => {
  return Decimal(x).toFixed(2)
}

const QuoteComponents = (props) => {
  const { components, onNewQuoteComponentRemoved } = props
  let costTotal = components.reduce((acc, component) => {
    return acc.plus(Decimal(component.cost))
  }, Decimal(0))
  let priceTotal = components.reduce((acc, component) => {
    return acc.plus(Decimal(component.price))
  }, Decimal(0))
  const taxes = priceTotal.times(0.16).toFixed(2)
  const grandDtotal = priceTotal.plus(taxes).toFixed(2)
  costTotal = costTotal.toFixed(2)
  priceTotal = priceTotal.toFixed(2)
  return (
    <Table striped bordered hover style={{ textAlign: 'right' }}>
      <thead>
        <tr>
          <th />
          <th>Concepto</th>
          <th>Cantidad</th>
          <th>Costo</th>
          <th>Precio</th>
        </tr>
      </thead>
      <tbody>
        {components.map((component) => {
          const { quantity, concept, cost, price, key } = component
          return (
            <tr key={`component-${key}`}>
              <td style={styles.centerText}>
                <Button
                  onClick={() => onNewQuoteComponentRemoved(key)}
                  variant='danger'
                >
                  <FaTrash />
                </Button>
              </td>
              <td>{concept}</td>
              <td>{toFixed(quantity)}</td>
              <td>{toFixed(cost)}</td>
              <td>{toFixed(price)}</td>
            </tr>
          )
        })}
        <tr>
          <td />
          <td>Subtotal</td>
          <td />
          <td>{costTotal}</td>
          <td>{priceTotal}</td>
        </tr>
        <tr>
          <td />
          <td>Impuestos</td>
          <td>IVA</td>
          <td />
          <td>{taxes}</td>
        </tr>
        <tr>
          <td />
          <td>Total</td>
          <td />
          <td>{costTotal}</td>
          <td>{grandDtotal}</td>
        </tr>
      </tbody>
    </Table>
  )
}

QuoteComponents.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      concept: PropTypes.string,
      type: PropTypes.string,
      key: PropTypes.string.isRequired,
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  onNewQuoteComponentRemoved: PropTypes.func.isRequired
}

export default QuoteComponents
