import React from 'react'
import EmployeesView from '../views/humanResources/EmployeesView'

const routes = [
  {
    path: 'human_resources/employees',
    element: <EmployeesView />,
    handle: {
      crumb: EmployeesView.breadcrumbs
    }
  }
]

export default routes
