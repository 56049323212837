import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import Row from 'react-bootstrap/Row'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BankAccountPicker from '../bank_accounts/BankAccountPicker'
import Button from 'react-bootstrap/Button'
import { FaUndoAlt } from 'react-icons/fa'

const ReportOptionsForm = (props) => {
  const { onOptionsChange, isCreditCard = true, refreshData } = props
  const [state, setState] = useState({
    startDate: props.startDate,
    endDate: props.endDate,
    groupBy: props.groupBy,
    bankAccountId: props.bankAccountId
  })
  const { startDate, endDate } = state

  const onChange = (field, value) => {
    setState((s) => ({ ...s, [field]: value }))
    onOptionsChange(field, value)
  }

  return (
    <Row key='date-inputs' className='justify-content-md-center'>
      <Col lg='2'>
        <Form.Label>Fecha Inicial</Form.Label>
        <DatePicker
          className='form-control'
          autoComplete='off'
          selected={startDate}
          onChange={(newValue) => onChange('startDate', newValue)}
          calendarStartDay={1}
        />
      </Col>
      <Col lg='2'>
        <Form.Label>Fecha Final</Form.Label>
        <DatePicker
          className='form-control'
          autoComplete='off'
          selected={endDate}
          onChange={(newValue) => onChange('endDate', newValue)}
          calendarStartDay={1}
        />
      </Col>
      <Col lg='2'>
        <Form.Label>Cuenta</Form.Label>
        <BankAccountPicker isCreditCard={isCreditCard} onUpdate={(newValue) => onChange('bankAccountId', newValue)} />
      </Col>
      <Col lg='1'>
        <div className='position-relative' style={{ height: '100%' }}>
          <Button style={{ position: 'absolute', bottom: '3px' }} onClick={refreshData}>
            <FaUndoAlt />
          </Button>
        </div>
      </Col>
    </Row>
  )
}

ReportOptionsForm.propTypes = {
  onOptionsChange: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  groupBy: PropTypes.string,
  bankAccountId: PropTypes.string,
  isCreditCard: PropTypes.bool
}

export default ReportOptionsForm
