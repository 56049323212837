import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import QuickTransaction from '../components/financialResources/transactions/QuickTransaction'

export default function HomeView () {
  const [state, setState] = useState({ showQuickTransaction: false })
  const { showQuickTransaction } = state
  const toggleQuickTransaction = () => {
    setState({ showQuickTransaction: !showQuickTransaction })
  }
  return (
    <Container>
      <div>
        <h3>Bienvenido a e-nventario.com</h3>
      </div>
      <div>
        <QuickTransaction
          showQuickTransaction={showQuickTransaction}
          toggleModal={toggleQuickTransaction}
          onSave={toggleQuickTransaction}
        />
      </div>
    </Container>
  )
}
