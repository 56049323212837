import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from 'uuid'
import BankAccountsService from '../../../services/bankAccountsService'
import PropTypes from 'prop-types'

const BankAccountPicker = (params) => {
  const { onUpdate, isCreditCard, initialAccountId = null } = params
  const [state, setState] = useState({
    accounts: {},
    accountId: initialAccountId
  })
  const { accounts, accountId } = state

  useEffect(() => {
    BankAccountsService.getAccounts(isCreditCard).then((response) => {
      const accounts = response.data || []
      const sortedAccounts = {}
      for (const account of accounts) {
        sortedAccounts[account.id] = account
      }

      setState((s) => ({
        ...s,
        accounts: sortedAccounts
      }))
    })
  }, [isCreditCard])

  const handleUpdate = ({ target: { value } }) => {
    setState({
      ...state,
      accountId: value
    })
    onUpdate(value, accounts[value])
  }

  return (
    <Form.Control
      as='select'
      value={accountId}
      onChange={handleUpdate}
      required
    >
      <option> </option>
      {Object.keys(accounts).map((accountId) => {
        const account = accounts[accountId]
        const accountLabel = `${account.bank} ${account.description} ${account.number}`
        return (
          <option key={`option-class-${uuid()}`} value={accountId}>
            {accountLabel}
          </option>
        )
      })}
    </Form.Control>
  )
}

BankAccountPicker.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  isCreditCard: PropTypes.bool,
  initialAccountId: PropTypes.number
}

export default BankAccountPicker
