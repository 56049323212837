import React, { useEffect, useState } from 'react'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'
import EntitiesList from '../../components/common/CRUD/EntitiesList'
import Button from 'react-bootstrap/Button'
import NewProjectModal from '../../components/sales/projects/NewProjectModal'

const { listItems, createItem } = createCRUDService('/api/sales/projects')

const ProjectsView = () => {
  const [state, setState] = useState({
    data: { rows: [] },
    showCreateModal: false,
    newProject: {
      name: '',
      clientId: ''
    }
  })
  useEffect(() => {
    listItems().then((responseData) => {
      setState((s) => ({ ...s, data: { rows: responseData } }))
    })
  }, [])

  const { data, showCreateModal, newProject } = state
  const handleProjectSelected = (project) => {
    const projectId = project.id
    window.location.href = `/sales/projects/${projectId}`
  }

  const toggleCreateModal = () =>
    setState((s) => ({ ...s, showCreateModal: !showCreateModal }))
  const createNewProject = async (newProject) => {
    const response = await createItem(newProject)
    const createdProject = response.data
    handleProjectSelected(createdProject)
  }

  const onProjectUpdate = (field, value) => {
    newProject[field] = value
    setState((s) => ({ ...s, newProject }))
  }

  return (
    <main style={{ padding: '1rem 0' }}>
      <Button className='mb-3' onClick={toggleCreateModal}>
        Nuevo Proyecto
      </Button>
      <EntitiesList
        data={data}
        titleProp='name'
        subTitleProp='client.name'
        onClick={handleProjectSelected}
      />
      <NewProjectModal
        show={showCreateModal}
        onCancel={toggleCreateModal}
        onSubmit={createNewProject}
        onProjectUpdate={onProjectUpdate}
        newProject={newProject}
      />
    </main>
  )
}

ProjectsView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-projects' active>
    Proyectos
  </Breadcrumb.Item>
)

export default ProjectsView
