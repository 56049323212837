import TransactionsService from '../../../services/transactionsService'
import Form from 'react-bootstrap/Form'
import AccountPicker from '../bank_accounts/BankAccountPicker'
import DatePicker from '../../common/CRUD/DatePicker'
import TagSchemaClassPicker from './TagSchemaClassPicker'
import Button from 'react-bootstrap/Button'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FaTrash } from 'react-icons/fa'
import { NumericFormat } from 'react-number-format'
import InputGroup from 'react-bootstrap/InputGroup'
import QuickTransfer from './QuickTransfer'
import dayjs from 'dayjs'
import { Decimal } from 'decimal.js'
import { handleFocus } from '../../../utils/DOMEvents'

const defaultTransaction = {
  date: new Date(),
  description: '',
  amount: 0
}

const initialState = {
  transactionStatus: {
    status: ''
  },
  isDelete: false
}

const QuickTransaction = (props) => {
  const {
    showQuickTransaction,
    toggleModal,
    onSave,
    update = false,
    bankAccountId,
    transaction = defaultTransaction
  } = props

  const [state, setState] = useState({
    ...initialState,
    quickTransaction: {
      ...transaction,
      amount: (transaction?.amount || 0) < 0 ? transaction.amount * -1 : transaction.amount,
      className: get(transaction, 'classes[0].name', '')
    },
    isCreditCard: props.isCreditCard,
    transactionType: (transaction?.amount || 0) < 0 ? 'negative' : 'positive'
  })

  const { quickTransaction, transactionStatus, isDelete, transactionType, isCreditCard } =
    state
  const updateTransaction = (field, value, selectedAccount) => {
    setState((s) => ({
      ...s,
      quickTransaction: { ...quickTransaction, [field]: value },
      isCreditCard: selectedAccount ? selectedAccount.isCreditCard : null
    }))
  }

  const createControlHandler = (field) => {
    const handleControlChange = (updateEvent) => {
      let updateValue = ''
      if (updateEvent.target) {
        updateValue = updateEvent.target.value
      } else {
        updateValue = updateEvent
      }
      updateTransaction(field, updateValue)
    }
    return handleControlChange
  }

  const handleDateChange = (field, value) => {
    updateTransaction(field, value)
  }

  const close = () => {
    setState({
      ...initialState,
      quickTransaction: {
        ...transaction,
        className: get(transaction, 'classes[0].name', '')
      }
    })
    onSave()
  }
  const scheduleClose = () => setTimeout(close, 1000)
  const updateTransactionStatus = (newStatus, payload) => {
    setState((s) => ({
      ...s,
      transactionStatus: { status: newStatus, payload }
    }))
    if (newStatus === 'success') scheduleClose()
  }

  const getFinalAmount = (transaction) => {
    const { amount } = transaction
    let finalAmount = new Decimal(amount)
    if (transactionType === 'negative') {
      const negativeOne = new Decimal(-1)
      if (finalAmount > 0) {
        finalAmount = finalAmount.times(negativeOne)
      }
    }
    return finalAmount.toFixed(2)
  }
  // This function is invoked when saving a transaction
  const handleSave = (service, transaction) => {
    return service.createTransaction({
      ...transaction,
      date: dayjs(transaction.date).format('YYYY-MM-DD'),
      amount: getFinalAmount(transaction),
      bankAccountId: transaction.bankAccountId || bankAccountId
    })
  }
  // This function is invoked when saving an updated transaction, not on state update
  const handleUpdate = (service, transaction) => {
    return service.updateTransaction({
      ...transaction,
      amount: getFinalAmount(transaction),
      bankAccountId: transaction.bankAccountId || bankAccountId
    })
  }

  const handleDelete = (service, transaction) => {
    return service.deleteTransaction(transaction.id)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let targetMethod = handleSave
    if (update) targetMethod = handleUpdate
    if (isDelete) targetMethod = handleDelete

    const service = new TransactionsService(
      quickTransaction.accountId || bankAccountId
    )
    targetMethod(service, quickTransaction)
      .then(() => {
        let message = 'Transacción '
        if (!update) message = `${message} creada`
        if (update && isDelete) message = `${message} borrada`
        if (update && !isDelete) message = `${message} actualizada`
        updateTransactionStatus('success', { message })
      })
      .catch((error) => updateTransactionStatus('error', error))
  }
  const handleSubmitStatus = () => {
    const { status, payload = {} } = transactionStatus
    if (status === '') return { showAlert: false }
    return {
      showAlert: true,
      alertVariant: status === 'error' ? 'danger' : 'success',
      alertMessage: payload?.message
    }
  }
  const { showAlert, alertVariant, alertMessage } = handleSubmitStatus()
  const toggleDelete = () => setState((s) => ({ ...s, isDelete: !isDelete }))
  const handleTransactionTypeUpdate = ({ target: { value } }) => {
    setState((s) => ({ ...s, transactionType: value }))
  }
  const positiveLabel = isCreditCard ? 'Compra' : 'Depósito'
  const negativeLabel = isCreditCard ? 'Pago' : 'Retiro'
  const amountLabel = transactionType === 'negative' ? '-$' : '$'
  const showTransactionDetails =
    isDelete === false && transactionType !== 'transfer'
  const showQuickTransfer = transactionType === 'transfer'

  const handleTransferClose = () => {
    setState((s) => ({ ...s, transactionType: 'positive' }))
    onSave()
  }

  if (showQuickTransaction && showQuickTransfer) {
    return (
      <QuickTransfer
        show={showQuickTransaction}
        handleTransactionTypeUpdate={handleTransactionTypeUpdate}
        onSave={handleTransferClose}
      />
    )
  }

  return (
    <>
      <Row className='justify-content-center mb-3 mt-3'>
        <Col xs='auto'>
          <Button onClick={toggleModal}>Transacción Rápida</Button>
        </Col>
      </Row>
      <Modal
        show={showQuickTransaction}
        onHide={toggleModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>
                {update ? (isDelete ? 'Borrar' : 'Editar') : 'Crear'}{' '}
                Transacción
                {update && (
                  <Col>
                    <Button
                      onClick={toggleDelete}
                      variant='danger'
                      style={{ position: 'absolute', right: 50, top: 15 }}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                )}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>}
            {!isDelete && (
              <Form.Control
                as='select'
                value={transactionType}
                onChange={handleTransactionTypeUpdate}
                required
              >
                <option value='positive'>{positiveLabel}</option>
                <option value='negative'>{negativeLabel}</option>
                {isCreditCard !== true && update !== true && (
                  <option value='transfer'>Transferencia</option>
                )}
              </Form.Control>
            )}
            {showTransactionDetails && (
              <>
                {!bankAccountId && (
                  <Form.Group controlId='accountId'>
                    <Form.Label>Cuenta</Form.Label>
                    <AccountPicker
                      initialAccountId={quickTransaction.bankAccountId}
                      isCreditCard={props.isCreditCard}
                      onUpdate={(updatedValue, selectedAccount) =>
                        updateTransaction(
                          'accountId',
                          updatedValue,
                          selectedAccount
                        )}
                    />
                  </Form.Group>
                )}
                <Form.Group controlId='date'>
                  <DatePicker
                    field={{ property: 'date', name: 'Fecha' }}
                    entity={quickTransaction}
                    onChange={handleDateChange}
                  />
                </Form.Group>
                <Form.Group controlId='description'>
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    type='text'
                    value={quickTransaction.description}
                    required
                    onChange={createControlHandler('description')}
                    onFocus={handleFocus}
                  />
                </Form.Group>
                <Form.Group controlId='amount'>
                  <Form.Label>Monto</Form.Label>
                  <InputGroup className='mb-3'>
                    <InputGroup.Text id='basic-addon1'>
                      {amountLabel}
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      required
                      value={quickTransaction.amount}
                      step='.01'
                      onChange={createControlHandler('amount')}
                      onFocus={handleFocus}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId='className'>
                  <Form.Label>Etiqueta</Form.Label>
                  <TagSchemaClassPicker
                    transaction={quickTransaction}
                    creditClasses={isCreditCard}
                    onTransactionUpdate={(transaction, field, newValue) =>
                      updateTransaction('className', newValue)}
                  />
                </Form.Group>
              </>
            )}
            {isDelete && (
              <>
                <h3>¿Realmente desea eliminar la siguiente transacción?</h3>
                <h2 style={{ color: 'red' }}>Esto no se puede deshacer</h2>
                <div>
                  Cuenta: {quickTransaction.bankAccount.bank}{' '}
                  {quickTransaction.bankAccount.description}{' '}
                  {quickTransaction.bankAccount.number}
                </div>
                <div>
                  Transacción: <br />
                  <ul>
                    <li>ID: {quickTransaction.id}</li>
                    <li>Fecha: {quickTransaction.date}</li>
                    <li>
                      Monto:{' '}
                      <NumericFormat
                        value={quickTransaction.amount}
                        prefix='$'
                        displayType='text'
                        thousandsGroupStyle='thousands'
                        thousandSeparator=','
                      />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Confirmar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

QuickTransaction.propTypes = {
  showQuickTransaction: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  update: PropTypes.bool,
  transaction: PropTypes.any,
  isCreditCard: PropTypes.bool,
  bankAccountId: PropTypes.number
}

export default QuickTransaction
