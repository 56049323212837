import { getAxios } from '../utils/requests'
const axios = getAxios()

export default class UsersService {
  static getUsers () {
    return axios.get('/api/admin/users')
  }

  static updateUser (userId, user) {
    return axios.put(`/api/admin/users/${userId}`, user)
  }

  static createUser (user) {
    // temp hack until we move users into users
    return axios.post('/api/admin/users', { ...user, userId: 1 })
  }

  static deleteUser (userId) {
    return axios.delete(`/api/admin/users/${userId}`)
  }

  static updatePassword (password, newPassword) {
    return axios.put('/api/system/password', { password, newPassword })
  }
}
