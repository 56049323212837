import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import {
  fields,
  getExtraActions
} from '../../models/materialResources/inventoryModel'
import { createCRUDService } from '../../services/crudService'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'

const InventoriesView = () => {
  const { companyId } = useParams()
  const extraActions = getExtraActions(companyId)
  const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
    '/api/material_resources/inventories'
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        extraActions={extraActions}
        entityName='Inventario'
        entityNamePlural='Inventarios'
        paginated
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

InventoriesView.breadcrumbs = (params) => {
  const { companyId } = params
  return [
    <Breadcrumb.Item key='breadcrumb-companies' href='/companies'>
      Compañias
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-company-id' active>
      {companyId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventories' active>
      Inventarios
    </Breadcrumb.Item>
  ]
}

export default InventoriesView
