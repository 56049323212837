import { Breadcrumb } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { FaInfoCircle, FaListOl, FaTrashAlt } from 'react-icons/fa'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import ConfirmModal from '../../components/common/ConfirmModal'
import { createCRUDService } from '../../services/crudService'
import { useParams } from 'react-router-dom'
import MenuDetails from '../../components/restaurant/menus/MenuDetails'
import MenuItems from '../../components/restaurant/menus/MenuItems'
import { debounce, get } from 'lodash'

const { getItem, updateItem } = createCRUDService('/api/restaurants/menus')

const styles = {
  navLink: {
    marginRight: '1em'
  },
  tabContent: {
    border: '1px groove #eaeaea',
    borderTop: 'none',
    paddingTop: '3em',
    paddingBottom: '3em'
  }
}

const MenuItemsView = () => {
  const { id } = useParams()
  const [state, setState] = useState({})
  const [refresh, setRefresh] = useState(0)
  const { menu, errors = [] } = state
  const menuItems = menu?.items || []
  const updateState = (newState) => {
    setState((s) => ({ ...s, ...newState }))
  }

  useEffect(() => {
    getItem(id, refresh)
      .then((data) => updateState({ menu: data, errors: [] }))
      .catch((error) => updateState({ errors: [error] }))
  }, [id, refresh])

  const onItemChanges = () => {
    setRefresh(refresh + 1)
  }

  const debouncedMenuUpdate = debounce((updatedMenu) => {
    updateItem(updatedMenu)
      .then(() => updateState({ errors: [] }))
      .catch((error) => updateState({ errors: [error] }))
  }, 400)
  const onMenuUpdate = (prop, value) => {
    menu[prop] = value
    updateState({ menu })
    debouncedMenuUpdate(menu)
  }

  return (
    <Container>
      <Row>
        <Col>
          {errors.map((error, index) => {
            const message = get(
              error,
              'response.data[0].message',
              'internal error'
            )
            return (
              <Alert key={`error-${index}`} variant='danger'>
                {message}
              </Alert>
            )
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Tab.Container defaultActiveKey='details'>
                <Nav variant='tabs' defaultActiveKey='details' fill>
                  <Nav.Item>
                    <Nav.Link eventKey='details'>
                      <FaInfoCircle size='2em' style={styles.navLink} />
                      Detalles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='products'>
                      <FaListOl size='2em' style={styles.navLink} /> Productos y
                      Platillos
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content style={styles.tabContent}>
                  {/** <==== DETAILS  ====> **/}
                  <Tab.Pane title='Detalles' eventKey='details'>
                    <Container>
                      <Row>
                        <Col className='text-right'>
                          <MenuDetails menu={menu} onChange={onMenuUpdate} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className='text-center'>
                          <Button
                            onClick={() => {}}
                            variant='danger'
                            className='mt-3'
                          >
                            <FaTrashAlt /> ELIMINAR Menú
                          </Button>
                        </Col>
                        <ConfirmModal
                          show={false}
                          title='Borrar proyecto'
                          onCancel={() => {}}
                          onConfirm={() => {}}
                        >
                          <div>¿Está seguro que desea borrar el proyecto?</div>
                        </ConfirmModal>
                      </Row>
                    </Container>
                  </Tab.Pane>
                  {/** <==== DETAILS  ====> **/}

                  {/** <==== COMPONENTS  ====> **/}
                  <Tab.Pane title='Productos y Platillos' eventKey='products'>
                    <Container>
                      <Row>
                        <Col>
                          <MenuItems
                            items={menuItems}
                            menuId={id}
                            onItemChanges={onItemChanges}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Tab.Pane>
                  {/** <==== COMPONENTS  ====> **/}
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

MenuItemsView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-menu-details-home' href='/' active>
    Elementos Menú
  </Breadcrumb.Item>
)

export default MenuItemsView
