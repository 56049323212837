import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'
export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Código',
    property: 'code'
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  })
  // parseField({
  //   name: 'Código de impuestos',
  //   property: 'taxCode',
  //   displayInTable: false
  // }),
  // parseField({
  //   name: 'Identificador de parte',
  //   property: 'partIdentifier',
  //   type: 'select',
  //   options: ['SERIAL_NUMBER', 'ORDER_ID', 'NONE'],
  //   displayInTable: false
  // }),
  // parseField({
  //   name: 'Unidades',
  //   property: 'units',
  //   type: 'select',
  //   options: ['PIECE'],
  //   displayInTable: false
  // })
]

export const getCatalogueActions = ({ onAddCallback }) => {
  const catalogueActions = [
    parseFieldAction(onAddCallback, 'FaPlusCircle', 'add')
  ]
  return catalogueActions
}
