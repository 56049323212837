import React, { useContext } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import AppContext from '../../AppContext'

const Header = () => {
  const { userModules } = useContext(AppContext)

  const modules = []
  for (const moduleName in userModules) {
    const moduleData = userModules[moduleName]
    const module = {
      title: moduleData.description,
      path: moduleData.path,
      children: []
    }
    for (const submoduleName in moduleData.submodules) {
      const submoduleData = moduleData.submodules[submoduleName]
      module.children.push({
        title: submoduleData.description,
        path: submoduleData.path
      })
    }
    modules.push(module)
  }

  return (
    <>
      <Navbar bg='light' expand='md' style={{ padding: '.5em' }}>
        <Navbar.Brand>
          <Link to='/' className='navbar-brand'>
            e-nventario
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            {modules.map((module) => {
              const moduleTitle = module.title
              const modulePath = module.path
              const submodules = module.children
              return (
                <NavDropdown title={moduleTitle} key={`${moduleTitle}_menu`} renderMenuOnMount>
                  {submodules.map((submodule) => {
                    const submoduleTitle = submodule.title
                    const submodulePath = submodule.path
                    return (
                      <NavDropdown.Item
                        as='div'
                        key={`${moduleTitle}_${submoduleTitle}_menu`}
                      >
                        <Link
                          to={`/${modulePath}/${submodulePath}`}
                          className='nav-link'
                        >
                          {submoduleTitle}
                        </Link>
                      </NavDropdown.Item>
                    )
                  })}
                </NavDropdown>
              )
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Header
