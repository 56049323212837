import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductsAndServicesCatalogue from './ProductsAndServicesCatalogue'
import PackageComponents from './packages/PackageComponents'

const ProductsAndServicesModal = (params) => {
  const { onClose, onSave, showPackages } = params
  const [state, setState] = useState({
    selectedComponents: []
  })
  const { selectedComponents } = state
  const onComponentAdd = (component) => {
    selectedComponents.push(component)
    setState({ ...state, selectedComponents })
  }

  const onComponentRemove = (index) => {
    selectedComponents.splice(index, 1)
    setState({ ...state, selectedComponents })
  }

  const handleSave = () => {
    onSave(selectedComponents)
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className='mb-3'>Catálogo de Productos y Servicios</div>
          <ProductsAndServicesCatalogue
            onAdd={onComponentAdd}
            showPackages={showPackages}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='mb-3'>Seleccionar componentes</div>
          <PackageComponents
            selectedComponents={selectedComponents}
            onComponentRemove={onComponentRemove}
          />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col className='d-flex justify-content-end mb-5'>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
        </Col>
        <Col>
          <Button variant='primary' onClick={handleSave}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductsAndServicesModal
