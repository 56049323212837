import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Código',
    property: 'code'
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  // parseField({
  //   name: 'Código de impuestos',
  //   property: 'taxCode',
  //   displayInTable: false
  // }),
  parseField({
    name: 'Descripción',
    property: 'description',
    displayInTable: false
  })
]

export const getCatalogueActions = ({ onAddCallback }) => {
  const catalogueActions = [
    parseFieldAction(onAddCallback, 'FaPlusCircle', 'add')
  ]
  return catalogueActions
}
