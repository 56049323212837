import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields, extraActions } from '../../models/financialResources/bankAccountModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
  '/api/financial_resources/bank_accounts'
)
const BankAccountsView = () => {
  return (
    <main style={{ padding: '1rem 0' }}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <CRUD
        fields={fields}
        paginated
        entityName='Cuenta bancaria'
        entityNamePlural='Cuentas bancarias'
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
        extraActions={extraActions}
        useTable
      />
    </main>
  )
}

BankAccountsView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-expenses' href='/bank_accounts' active>
    Cuentas Bancarias
  </Breadcrumb.Item>
)

export default BankAccountsView
