import React, { useEffect, useState } from 'react'
import { Table, Button, Breadcrumb } from 'react-bootstrap'
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { getInventoryStock } from '../../services/itemsService'

const InventoryStockView = () => {
  const [stockItems, setStockItems] = useState([])
  const { companyId, inventoryId } = useParams()
  useEffect(() => {
    getInventoryStock(companyId, inventoryId)
      .then((items) => setStockItems(items))
      .catch((err) =>
        console.error(`Error getting items! ${JSON.stringify(err)}`)
      )
  }, [companyId, inventoryId])
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID #</th>
            <th>Nombre</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {stockItems.map((item) => (
            <tr key={`stock-items-${item.id}`}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button className='mr-2' variant='link'>
        <a href={`/companies/${companyId}/inventories/${inventoryId}/in`}>
          <FaSignInAlt /> Agregar al stock de inventario
        </a>
      </Button>
      <Button className='mr-2' variant='link'>
        <a href={`/companies/${companyId}/inventories/${inventoryId}/out`}>
          <FaSignOutAlt /> Eliminar del stock de inventario
        </a>
      </Button>
    </>
  )
}

InventoryStockView.breadcrumbs = (params) => {
  const { companyId, inventoryId } = params
  return [
    <Breadcrumb.Item key='breadcrumb-companies' href='/companies'>
      Compañias
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-company-id' active>
      {companyId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item
      key='breadcrumb-inventories'
      href={`/companies/${companyId}/inventories`}
    >
      Inventarios
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventory-id' active>
      {inventoryId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventories-stock' active>
      Existencias
    </Breadcrumb.Item>
  ]
}

export default InventoryStockView
