import React, { useState, useContext } from 'react'
import UserDetailsModal from '../../components/admin/UserDetailsModal'
import AppContext from '../../AppContext'
import { Navigate } from 'react-router-dom'

const UserDetailsView = () => {
  const [redirect, setRedirect] = useState(false)
  const { user } = useContext(AppContext)
  if (redirect) return <Navigate to='/' />
  return (
    <UserDetailsModal
      initialUser={user}
      showModal
      onCancel={() => setRedirect(true)}
    />
  )
}

export default UserDetailsView
