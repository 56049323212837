import React from 'react'
import PropTypes from 'prop-types'
import SaveModal from './SaveModal'

const CRUDModal = (props) => {
  const { action, fields, entityName, entity, onCancel, onSubmit, onDelete, loaderData } =
    props
  if (action === 'create' || action === 'update') {
    return (
      <SaveModal
        action={action}
        entity={entity}
        fields={fields}
        onCancel={onCancel}
        onSubmit={onSubmit}
        entityName={entityName}
        onDelete={onDelete}
        loaderData={loaderData}
      />
    )
  }
  return <></>
}

CRUDModal.propTypes = {
  action: PropTypes.string,
  fields: PropTypes.array.isRequired,
  entityName: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loaderData: PropTypes.any,
  customModalBody: PropTypes.any
}

export default CRUDModal
