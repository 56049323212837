import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Fa from 'react-icons/fa'
import Button from 'react-bootstrap/Button'
import { v4 } from 'uuid'
import { Navigate } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
const EntityTableActions = (props) => {
  const [state, setState] = useState({ redirect: false, redirectTo: '/' })
  const { redirect, redirectTo } = state
  if (redirect === true) {
    return <Navigate to={redirectTo} />
  }
  const handleRedirectTo = (to) => setState(() => ({ ...state, redirect: true, redirectTo: to }))
  const { actions = [], entity } = props
  return (
    <>
      {actions.map((action, index) => {
        if (action.component) return action.component(entity, handleRedirectTo)
        const ActionIcon = Fa[action.icon]
        return (
          <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            key={`extra-action-${index}`}
            overlay={(props) => {
              return (
                <Tooltip id='button-tooltip' {...props}>
                  {action.tooltip}
                </Tooltip>
              )
            }}
          >
            <Button
              key={`extra-action-button-${v4()}`}
              size='sm'
              style={{ marginRight: '5px' }}
              onClick={() => action.callback(entity, handleRedirectTo)}
            >
              <ActionIcon />
            </Button>
          </OverlayTrigger>
        )
      })}
    </>
  )
}

EntityTableActions.propTypes = {
  actions: PropTypes.array.isRequired,
  entity: PropTypes.object.isRequired
}

export default EntityTableActions
