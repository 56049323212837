import React, { useContext, useEffect, useState } from 'react'
import { debounce, get, set } from 'lodash'
import { Breadcrumb } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { createCRUDService } from '../../services/crudService'
import AlertMessage from '../../components/system/AlertMessage'
import { v4 } from 'uuid'
import {
  FaCashRegister,
  FaFileInvoiceDollar,
  FaInfoCircle,
  FaListOl,
  FaTrashAlt
} from 'react-icons/fa'
import ProjectComponent from '../../components/sales/projects/ProjectComponent'
import ProjectDetails from '../../components/sales/projects/ProjectDetails'
import EditComponent from '../../components/sales/projects/EditComponent'
import ProjectQuoteModal from '../../components/sales/projects/ProjectQuoteModal'
import QuoteListComponent from '../../components/sales/projects/QuoteListComponent'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import ConfirmModal from '../../components/common/ConfirmModal'
import NewPaymentModal from '../../components/sales/projects/NewPaymentModal'
import PaymentsList from '../../components/sales/projects/PaymentsList'
import AppContext from '../../AppContext'

const { getItem, updateItem, deleteItem } = createCRUDService(
  '/api/sales/projects'
)

const { createItem: createQuote, deleteItem: deleteQuote } =
  createCRUDService('/api/sales/quotes')

const addKeys = (arrayOfObjects = []) => {
  return arrayOfObjects.map((obj) => {
    return {
      ...obj,
      key: v4()
    }
  })
}
const sortByCreationDate = (items = []) => {
  const itemsWithDates = items.map((item) => ({
    ...item,
    createdAt: new Date(item.createdAt)
  }))
  return itemsWithDates.sort(
    (itemA, itemB) => itemA.createdAt < itemB.createdAt
  )
}
const addKeysAndSort = (data) => {
  data.components = addKeys(data.components)
  data.quotes = addKeys(data.quotes)
  data.quotes = sortByCreationDate(addKeys(data.quotes))
  data.payments = addKeys(data.payments)
  data.payments = sortByCreationDate(addKeys(data.payments))
}

const handleSave = (project, stateOverrides = {}, callback) => {
  // if (isDelete) return handleDelete();
  const targetMethod = updateItem
  return targetMethod(project).then((result) => {
    const newProject = result.data
    addKeysAndSort(newProject)
    callback(undefined, {
      message: 'Cambios guardados',
      body: {
        ...stateOverrides,
        myProject: { ...newProject }
      }
    })
  })
}
const debouncedSave = debounce(handleSave, 400)

const newPayment = { type: 'cash', notes: '', date: new Date(), transaction: { amount: 0, bankAccountId: null } }

const ProjectManagementView = () => {
  const { userModules } = useContext(AppContext)
  const hasAccountsModule =
    get(userModules, 'financial_resources.submodules.bank_accounts', false) !==
    false
  const hasQuotesModule =
    get(userModules, 'sales.submodules.quotes', false) !== false

  const [state, setState] = useState({
    myProject: { id: '', name: '', code: '', description: '', components: [] },
    editMode: false,
    newQuote: false,
    showDeleteModal: false,
    showPaymentModal: false,
    paymentToEdit: newPayment,
    alerts: [],
    refresh: 1
  })

  console.log(userModules)
  const clearAlerts = () => setState((s) => ({ ...s, alerts: [] }))
  const {
    myProject,
    alerts,
    componentToEdit,
    componentToEditKey,
    editMode,
    newQuote,
    refresh,
    showDeleteModal,
    showPaymentModal,
    paymentToEdit
  } = state
  const { components, quotes = [], payments = [] } = myProject

  const { id } = useParams()

  useEffect(() => {
    getItem(id).then((data) => {
      addKeysAndSort(data)
      setState((s) => ({ ...s, myProject: data }))
    })
  }, [id, refresh])

  const addComponent = () => {
    const key = v4()
    const component = {
      type: 'product',
      name: '',
      cost: 0,
      price: 0,
      quantity: 1,
      key
    }
    setState((s) => ({
      ...s,
      myProject,
      componentToEdit: component,
      componentToEditKey: key,
      editMode: true
    }))
  }

  const handleError = (error) => {
    const errorData = get(error, 'response.data')
    if (errorData) {
      setState({
        ...state,
        alerts: [{ level: 'error', message: JSON.stringify(errorData) }]
      })
    } else {
      setState({
        ...state,
        alerts: [{ level: 'error', message: 'Unexpected error' }]
      })
    }
    setTimeout(clearAlerts, 1500)
  }
  const handleSuccess = (error, { message, stateOverrides = {} }) => {
    if (error) return handleError(error)
    setState({
      ...state,
      ...stateOverrides,
      alerts: [{ level: 'success', message }],
      editMode: false,
      refresh: refresh + 1, // Trigger a data reload
      newPayment: { amount: 0, type: 'cash', notes: '', date: new Date() },
      showPaymentModal: false
    })
    setTimeout(clearAlerts, 1500)
  }

  const handleDelete = () => {
    deleteItem(myProject)
      .then(() => (window.location = '/projects'))
      .catch(handleError)
  }

  const handleProjectUpdate = (field, newValue) => {
    myProject[field] = newValue
    setState((s) => ({ ...s, myProject }))
    debouncedSave({ ...myProject }, {}, handleSuccess)
  }

  const onComponentRemove = () => {
    myProject.components = myProject.components.filter(
      ({ key }) => key !== componentToEditKey
    )
    return handleSave(
      myProject,
      {
        editMode: false
      },
      handleSuccess
    )
  }

  const onComponentUpdate = (field, value) => {
    setState((s) => ({
      ...s,
      componentToEdit: { ...componentToEdit, [field]: value }
    }))
  }

  const findComponent = (componentKey) =>
    components.find(({ key }) => key === componentKey)

  const onComponentEdit = (componentKey) => {
    const component = findComponent(componentKey)
    setState((s) => ({
      ...s,
      componentToEdit: component,
      componentToEditKey: componentKey,
      editMode: true
    }))
  }

  const onComponentSave = () => {
    if (!componentToEdit.id) {
      myProject.components.push(componentToEdit)
    } else {
      myProject.components = components.map((component) => {
        if (component.key === componentToEditKey) return componentToEdit
        return component
      })
    }
    return handleSave(
      myProject,
      {
        editMode: false
      },
      handleSuccess
    )
  }

  const onComponentEditCancel = () => {
    setState((s) => ({
      ...s,
      editMode: false,
      paymentToEdit: newPayment
    }))
  }

  const handleNewQuote = () => {
    setState((s) => ({ ...s, newQuote: true }))
  }

  const handleNewQuoteCancel = () => {
    setState((s) => ({ ...s, newQuote: false, refresh: refresh + 1 }))
  }

  const onNewQuoteComponentRemoved = (key) => {
    myProject.components = components.filter((component) => component.key !== key)
    setState((s) => ({ ...s }))
  }

  const handleNewQuoteSave = async () => {
    try {
      await createQuote({
        projectId: id,
        components
      })
      handleSuccess(undefined, {
        message: 'Cambios guardados',
        stateOverrides: {
          newQuote: false,
          refresh: refresh + 1 // Trigger a data reload
        }
      })
    } catch (err) {
      handleError(err)
    }
  }

  const styles = {
    navLink: {
      marginRight: '1em'
    }
  }

  const toggleDeleteModal = () => {
    setState((s) => ({ ...s, showDeleteModal: !showDeleteModal }))
  }

  const togglePaymentModal = (payment = newPayment) => {
    setState((s) => ({
      ...s,
      showPaymentModal: !showPaymentModal,
      paymentToEdit: payment
    }))
  }

  const handlePaymentSave = async () => {
    const { bankAccountId } = paymentToEdit
    const {
      createItem: createPayment,
      updateItem: updatePayment
    } = createCRUDService(`/api/sales/projects/${bankAccountId}/payments`)
    try {
      if (paymentToEdit.id) {
        return updatePayment(paymentToEdit).then(() => {
          handleSuccess(undefined, {
            message: 'Cambios guardados'
          })
        })
      } else {
        return createPayment({
          ...paymentToEdit,
          projectId: id
        }).then(() => {
          handleSuccess(undefined, {
            message: 'Cambios guardados'
          })
        })
      }
    } catch (err) {
      handleError(err)
    }
  }

  const handlePaymentUpdate = ({ target }) => {
    const field = target.id
    const { value } = target
    set(paymentToEdit, field, value)
    setState((s) => ({ ...s, newPayment }))
  }
  const handleDeleteQuote = async (quoteToDelete) => {
    console.log('delete quote!', quoteToDelete)
    await deleteQuote(quoteToDelete)
    handleSuccess(undefined, { message: 'Cotización borrada' })
  }

  const handleDeletePayment = async (paymentToDelete) => {
    console.log('delete payment!', paymentToDelete)
    const { bankAccountId } = paymentToDelete
    const {
      deleteItem: deletePayment
    } = createCRUDService(`/api/sales/projects/${bankAccountId}/payments`)
    return deletePayment(paymentToDelete).then(() => {
      handleSuccess(undefined, { message: 'Pago borrado' })
    })
  }

  return (
    <Container>
      <div style={{ position: 'fixed', top: '50' }}>
        {alerts.map(({ level, message }) => (
          <AlertMessage key={v4()} level={level} message={message} />
        ))}
      </div>
      <Card>
        <Card.Body>
          <Tab.Container defaultActiveKey='details'>
            <Nav variant='tabs' defaultActiveKey='details' fill>
              <Nav.Item>
                <Nav.Link eventKey='details'>
                  <FaInfoCircle size='2em' style={styles.navLink} />
                  Detalles
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='components'>
                  <FaListOl size='2em' style={styles.navLink} /> Componentes
                </Nav.Link>
              </Nav.Item>
              {hasQuotesModule && (
                <Nav.Item>
                  <Nav.Link eventKey='quotes'>
                    <FaFileInvoiceDollar size='2em' style={styles.navLink} />{' '}
                    Cotizaciones
                  </Nav.Link>
                </Nav.Item>
              )}
              {hasAccountsModule && (
                <Nav.Item>
                  <Nav.Link eventKey='payments'>
                    <FaCashRegister size='2em' style={styles.navLink} /> Pagos
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              {/** <==== DETAILS  ====> **/}
              <Tab.Pane title='Detalles' eventKey='details'>
                <ProjectDetails
                  id={myProject.id}
                  name={myProject.name}
                  totalHours={myProject.totalHours}
                  clientId={myProject.clientId}
                  disableClientUpdate
                  handleProjectUpdate={handleProjectUpdate}
                />
              </Tab.Pane>
              {/** <==== DETAILS  ====> **/}

              {/** <==== COMPONENTS  ====> **/}
              <Tab.Pane title='Componentes' eventKey='components'>
                <Container>
                  {components.map((component, index) => {
                    return (
                      <Row key={`component-${index}`}>
                        <Col>
                          <ProjectComponent
                            onComponentEdit={onComponentEdit}
                            component={component}
                            key={component.key}
                          />
                        </Col>
                      </Row>
                    )
                  })}
                  <Row>
                    <Col className='text-right'>
                      <Button variant='primary' onClick={addComponent}>
                        Añadir componente de proyecto
                      </Button>
                    </Col>
                  </Row>
                </Container>
                <EditComponent
                  show={editMode}
                  component={componentToEdit}
                  onHide={onComponentEditCancel}
                  onComponentUpdate={onComponentUpdate}
                  onComponentSave={onComponentSave}
                  onComponentRemove={onComponentRemove}
                />
              </Tab.Pane>
              {/** <==== COMPONENTS  ====> **/}

              {/** <==== QUOTES  ====> **/}
              {hasQuotesModule && (
                <Tab.Pane title='Cotizaciones' eventKey='quotes'>
                  <Row>
                    <Col>
                      <QuoteListComponent
                        quotes={quotes}
                        handleDeleteQuote={handleDeleteQuote}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button onClick={handleNewQuote}>Nueva Cotización</Button>
                    </Col>
                  </Row>
                  <ProjectQuoteModal
                    projectId={id}
                    onCancel={handleNewQuoteCancel}
                    components={components}
                    show={newQuote}
                    onQuoteSave={handleNewQuoteSave}
                    onNewQuoteComponentRemoved={onNewQuoteComponentRemoved}
                  />
                </Tab.Pane>
              )}
              {/** <==== QUOTES  ====> **/}

              {/** PAYMENTS **/}
              {hasAccountsModule && (
                <Tab.Pane title='Pagos' eventKey='payments'>
                  <Row>
                    <Col>
                      <PaymentsList
                        onPaymentEdit={togglePaymentModal}
                        payments={payments}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button onClick={() => togglePaymentModal()}>
                        Nuevo Pago
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <NewPaymentModal
                      show={showPaymentModal}
                      onCancel={() => togglePaymentModal()}
                      onConfirm={handlePaymentSave}
                      onPaymentUpdate={handlePaymentUpdate}
                      payment={paymentToEdit}
                      handleDeletePayment={handleDeletePayment}
                    />
                  </Row>
                </Tab.Pane>
              )}
              {/** PAYMENTS **/}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
      <Row>
        <Col className='text-center'>
          <Button onClick={toggleDeleteModal} variant='danger' className='mt-3'>
            <FaTrashAlt /> ELIMINAR PROYECTO
          </Button>
        </Col>
        <ConfirmModal
          show={showDeleteModal}
          title='Borrar proyecto'
          onCancel={toggleDeleteModal}
          onConfirm={handleDelete}
        >
          <div>¿Está seguro que desea borrar el proyecto?</div>
        </ConfirmModal>
      </Row>
    </Container>
  )
}

ProjectManagementView.breadcrumbs = () => [
  <Breadcrumb.Item key='breadcrumb-projects' as='span' linkAs='span'>
    <Link to='/sales/projects'>Proyectos</Link>
  </Breadcrumb.Item>,
  <Breadcrumb.Item key='breadcrumb-projects-creation' active>
    Gestión de proyectos
  </Breadcrumb.Item>
]

export default ProjectManagementView
