import React from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

const EditableSelect = (props) => {
  const { config, onChange, fieldName, isEditMode } = props
  const { fieldLabel, options, defaultValue } = config
  const handleUpdate = (event) => {
    const { value } = event.target
    onChange(fieldName, value)
  }

  return (
    <>
      <Form.Label>{fieldLabel}</Form.Label>
      <Form.Select
        onChange={handleUpdate}
        value={defaultValue}
        disabled={!isEditMode}
      >
        {options.map((option) => {
          const { value, label } = option
          return (
            <option key={uuid()} value={value}>
              {label}
            </option>
          )
        })}
      </Form.Select>
    </>
  )
}

EditableSelect.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  config: PropTypes.exact({
    defaultValue: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.exact({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  }),
  onChange: PropTypes.func.isRequired
}

const EditableText = (props) => {
  const { config, onChange, fieldName, isEditMode } = props
  const { fieldLabel, defaultValue = '' } = config
  const handleUpdate = (event) => {
    const { value } = event.target
    onChange(fieldName, value)
  }
  return (
    <Form.Group className='mb-3'>
      <Form.Label>{fieldLabel}</Form.Label>
      <Form.Control
        type='text'
        value={defaultValue}
        onChange={handleUpdate}
        disabled={!isEditMode}
      />
    </Form.Group>
  )
}

EditableText.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  config: PropTypes.exact({
    defaultValue: PropTypes.string,
    fieldLabel: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired
}

const EditableNumber = (props) => {
  const { config, onChange, fieldName, isEditMode } = props
  const { fieldLabel, defaultValue } = config
  const handleUpdate = (event) => {
    const { value } = event.target
    onChange(fieldName, value)
  }
  return (
    <Form.Group className='mb-3'>
      <Form.Label>{fieldLabel}</Form.Label>
      <Form.Control
        type='number'
        value={defaultValue}
        onChange={handleUpdate}
        disabled={!isEditMode}
      />
    </Form.Group>
  )
}

EditableNumber.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  config: PropTypes.exact({
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fieldLabel: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired
}

const fieldTypes = {
  select: EditableSelect,
  text: EditableText,
  number: EditableNumber
}

const EditableField = (props) => {
  const { fieldType, fieldName, config, onChange, isEditMode = true } = props
  const EditableComponent = fieldTypes[fieldType]
  if (!EditableComponent) { throw new Error(`Unable to resolve editable component ${fieldType}`) }

  return (
    <Form.Group className='mb-3'>
      <EditableComponent
        config={config}
        onChange={onChange}
        fieldName={fieldName}
        isEditMode={isEditMode}
      />
    </Form.Group>
  )
}

EditableField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool,
  config: PropTypes.exact({
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fieldLabel: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.exact({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  }),
  onChange: PropTypes.func.isRequired
}

export default EditableField
