import Form from 'react-bootstrap/Form'
import React, { useEffect, useState } from 'react'
import { createCRUDService } from '../../services/crudService'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'

const { listItems } = createCRUDService('/api/sales/clients')

const ClientPicker = (props) => {
  const { onClientUpdate, required, initialClientId = '', disabled } = props
  const [state, setState] = useState({
    clients: [],
    clientId: initialClientId
  })
  const { clients, clientId } = state
  if (initialClientId !== clientId) {
    setState((s) => ({ ...s, clientId: initialClientId }))
  }

  useEffect(() => {
    listItems().then((response) => {
      const { rows } = response
      setState((s) => ({
        ...s,
        clients: rows || []
      }))
    })
  }, [])
  return (
    <Form.Control
      as='select'
      value={clientId}
      required={required}
      disabled={disabled}
      onChange={(event) => {
        const {
          target: { value }
        } = event
        setState({ ...state, clientId: value })
        onClientUpdate(value)
      }}
    >
      <option> </option>
      {clients.map((client) => {
        return (
          <option key={`option-class-${v4()}`} value={client.id}>
            {client.name}
          </option>
        )
      })}
    </Form.Control>
  )
}

ClientPicker.propTypes = {
  onClientUpdate: PropTypes.func.isRequired,
  required: PropTypes.bool,
  initialClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool
}

export default ClientPicker
