import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

const TagSchemaClassGoalsReport = (props) => {
  const {
    dataSource,
    startDate,
    endDate
  } = props
  const [data, setData] = useState([])

  useEffect(() => {
    dataSource(startDate, endDate).then((data) => {
      const totalData = Object.keys(data).map((className) => {
        return {
          data: data[className],
          className
        }
      })
      setData(totalData)
    })
  }, [startDate, endDate, dataSource])
  return (
    <div className='centered'>
      <Row>
        <Col>
          <h3>Metas por etiqueta</h3>
        </Col>
      </Row>
      <Tab.Container defaultActiveKey={0}>
        <Nav variant='tabs' fill>
          {data.map(({ className }, index) => {
            return (
              <Nav.Item key={`menu-button-target-${index}`}>
                <Nav.Link eventKey={className}>
                  {className}
                </Nav.Link>
              </Nav.Item>
            )
          })}
        </Nav>
        <Tab.Content>
          {data.map(({ className, data: valueData }, index) => {
            return (
              <Tab.Pane title='Detalles' eventKey={className} key={`target-${index}`}>
                <div className='centered'>
                  <div style={{ height: '70vh', marginBottom: '5em' }}>
                    <ResponsiveLine
                      data={valueData.data}
                      margin={{ top: 50, right: 130, bottom: 60, left: 100 }}
                      xScale={{ type: 'point' }}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Semana',
                        legendOffset: 36,
                        legendPosition: 'middle'
                      }}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -50,
                        legend: 'Pesos',
                        legendPosition: 'middle'
                      }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabelYOffset={-12}
                      useMesh
                      legends={[
                        {
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: -20,
                          translateY: 60,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                              }
                            }
                          ]
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab.Pane>
            )
          })}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

TagSchemaClassGoalsReport.propTypes = {
  dataSource: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default TagSchemaClassGoalsReport
