import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'

export const fields = [
  parseField({
    name: 'ID',
    property: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Clave',
    property: 'name',
    editDisabled: true
  }),
  parseField({
    name: 'Descripción',
    property: 'description'
  })
]

export const extraActions = [
  parseFieldAction(
    (schema) => (window.location.href = `/schemas/${schema.id}/classes`),
    'FaListOl',
    'Classes'
  )
]
