import { Breadcrumb } from 'react-bootstrap'
import React from 'react'

const OrdersView = () => {
  return <div>Orders!</div>
}

OrdersView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-home' href='/' active>
    Órdenes
  </Breadcrumb.Item>
)

export default OrdersView
