import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import { getBalanceDetailReport } from '../../../services/reportsService'
import { Container } from 'react-bootstrap'

const DebitBalanceDetailReport = (props) => {
  const {
    startDate,
    endDate,
    refresh,
    bankAccountId
  } = props
  const [data, setState] = useState({ data: [] })

  useEffect(() => {
    async function fetchData () {
      const response = await getBalanceDetailReport({
        startDate,
        endDate,
        refresh,
        bankAccountId
      })
      return response.data
    }
    setState((s) => ({ ...s, data: [] }))
    fetchData().then((responseData) => setState((s) => ({ ...s, data: responseData })))
  }, [startDate, endDate, bankAccountId, refresh])
  return (
    <Container className='mt-5 mb-5'>
      <div className='centered'>
        <Row>
          <Col>
            <h3>Detalle del balance</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: '70vh', marginBottom: '5em' }}>
              <ResponsiveLine
                data={data.data}
                margin={{ top: 50, right: 130, bottom: 100, left: 100 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto' }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 90,
                  legend: 'Fecha',
                  legendOffset: 80,
                  legendPosition: 'middle'
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendOffset: -50,
                  legend: 'Pesos',
                  legendPosition: 'middle'
                }}
                pointBorderWidth={2}
                pointColor={{ theme: 'background' }}
                pointLabelYOffset={-12}
                useMesh
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateY: -50,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

DebitBalanceDetailReport.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  refresh: PropTypes.number,
  bankAccountId: PropTypes.string
}

export default DebitBalanceDetailReport
