import React from 'react'

const valueParsers = {
  datetime (dateToParse) {
    const parsedDate = new Date(dateToParse)
    const day = parsedDate.getDate()
    const month = parsedDate.getMonth() + 1
    const year = parsedDate.getFullYear()
    return `${year}/${month}/${day}`
  },
  select (value, field) {
    const [option] = field.options.filter((o) => o.value === value)
    return option.label
  }
}
const parseValue = (field, entity) => {
  const valueType = field.type
  const targetProp = field.property || field.name
  if (valueType === 'boolean') {
    return entity[targetProp] === false ? 'No' : 'Si'
  }
  const valueParser = valueParsers[valueType]
  const value = (field.type === 'custom') ? field.render(entity) : entity[targetProp]

  if (typeof valueParser === 'function') {
    return valueParser(value, field)
  }

  if (valueType === 'custom' && typeof field.renderTable === 'function') {
    return field.renderTable(entity)
  }

  if (field.formatter) {
    return field.formatter(value)
  }

  return value
}

export default function FieldColumns (props) {
  const { fields, entity } = props
  const fieldsToRender = fields.filter((f) => f.displayInTable)
  return fieldsToRender.map((field) => {
    const value = parseValue(field, entity)
    return <td key={`value-${field.name}`}>{value}</td>
  })
}
