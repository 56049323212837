import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Código',
    property: 'code'
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  parseField({
    name: 'Nombre comercial',
    property: 'commercialName',
    displayInTable: false
  }),
  parseField({
    name: 'Divisa',
    property: 'currency',
    displayInTable: false,
    type: 'select',
    options: ['USD', 'MXN']
  }),
  parseField({
    name: 'Moneda en Documentos',
    property: 'currencyInDocuments',
    displayInTable: false,
    type: 'select',
    options: ['USD', 'MXN']
  })
]
