import React, { useEffect, useState } from 'react'

import { getTotalReport } from '../../../services/reportsService'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NumericFormat } from 'react-number-format'

const Totals = (props) => {
  const { startDate, endDate, refresh, bankAccountId } = props
  const [state, setState] = useState({
    data: []
  })
  const { data } = state
  useEffect(() => {
    async function fetchData () {
      const response = await getTotalReport({ startDate, endDate, refresh, bankAccountId })
      return response.data
    }
    fetchData().then((data) => setState((s) => ({ ...s, data })))
  }, [startDate, endDate, refresh, bankAccountId])
  return (
    <Container className='mt-5 mb-5'>
      <Row>
        <Col>
          <h4>Totales</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            <div>
              Depósitos:
            </div>
            <NumericFormat
              value={data?.totalDeposits || 0}
              prefix='$'
              displayType='text'
              thousandsGroupStyle='thousands'
              thousandSeparator=','
            />
          </h4>
        </Col>
        <Col>
          <h4>
            <div>
              Retiros:
            </div>
            <NumericFormat
              value={data?.totalWithdrawals || 0}
              prefix='$'
              displayType='text'
              thousandsGroupStyle='thousands'
              thousandSeparator=','
            />
          </h4>
        </Col>
        <Col>
          <h4>
            <div>
              Balance Inicial:
            </div>
            <NumericFormat
              value={data?.initialBalance || 0}
              prefix='$'
              displayType='text'
              thousandsGroupStyle='thousands'
              thousandSeparator=','
            />
          </h4>
        </Col>
        <Col>
          <h4>
            <div>
              Balance Periodo:
            </div>
            <NumericFormat
              value={data?.periodBalance || 0}
              prefix='$'
              displayType='text'
              thousandsGroupStyle='thousands'
              thousandSeparator=','
            />
          </h4>
        </Col>
        <Col>
          <h4>
            <div>
              Balance Final:
            </div>
            <NumericFormat
              value={data?.latestBalance || 0}
              prefix='$'
              displayType='text'
              thousandsGroupStyle='thousands'
              thousandSeparator=','
            />
          </h4>
        </Col>
      </Row>
    </Container>
  )
}

Totals.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  bankAccountId: PropTypes.string,
  refresh: PropTypes.number
}

export default Totals
