import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/restaurants/tablesModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const TablesView = () => {
  const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
    '/api/restaurants/tables'
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='mesa'
        entityNamePlural='mesas'
        createButtonLabel='Crear nueva mesa'
        showAllInList
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

TablesView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-tables' href='/tables' active>
    Tables
  </Breadcrumb.Item>
)

export default TablesView
