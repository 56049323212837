import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'name'
  }),
  parseField({
    name: 'units'
  }),
  parseField({
    name: 'code',
    displayInTable: false
  }),
  parseField({
    name: 'description',
    type: 'textarea'
  })
]
