import React from 'react'
import { Pagination } from 'react-bootstrap'

const EntitiesTablePagination = (params) => {
  const { data = {}, onPaginationChange } = params
  const { total, limit = 10, offset = 0, count } = data
  if (!total) return <></>

  const handlePaginationChange = (pageNumber) => {
    // We calculate the new offset with the page number and the limit
    // if the limit is ten, and we want page 2, then we need to set an offset of 10
    // you could say that you want the limit * (pageNumber - 1)
    // ie page 2 would be 10 * (2 - 1) == 10
    // if we want page 3 we need an offset of 20
    // 10 * (3 - 1) == 20
    const newOffset = limit * (pageNumber - 1)
    onPaginationChange({ limit, offset: newOffset })
  }

  // We calculate the number of pages by dividing the total by the offset
  // ie 50 records with a limit of 10 would mean 5 pages
  // but 51 records would be 6 pages, so we need to round up!
  // 51/10 === 5.1 which rounded up is 6
  const numberOfPages = Math.ceil(total / limit)
  if (numberOfPages === Infinity) {
    console.error(
      'Error calculating number of pages, aborting to avoid freezing the browser'
    )
    return <></>
  }

  // offset is 0 based, if we have a limit of 10 and an offset of 10, we are seeing page 2 (record 11)
  // 10/10 === 1 so, (10/10) + 1 === 2. Which means that we can divide the offset by the limit and add one to know the page
  // if that is true then 20/10 === 2 so, (20/10) + 1 === 3 which is true because it would be record 21 and forwards

  function getPagesToRender (totalPages, limit, start) {
    const total = parseInt(totalPages)
    const truncatePages = total > limit
    const pagesToRender = []
    if (truncatePages) {
      const hasEnoughToRender = start <= totalPages - limit
      // we know we will truncate at 3 pages, so we will display pages 1, 2, 3, ...,  (the last 3 page numbers)
      const startFrom = hasEnoughToRender ? start : total - (limit - 1)
      for (let i = 0; i < pageNumberLimit - 3; i++) {
        pagesToRender.push(startFrom + i)
      }
      return pagesToRender.concat(['...', total - 2, total - 1, total])
    } else {
      return [1, 2, 3, 4, 5, 6].filter((i) => i > limit)
    }
  }
  const pageNumber = offset / limit + 1
  const pageItems = []
  const pageNumberLimit = 10
  const pagesToRender = getPagesToRender(
    numberOfPages,
    pageNumberLimit,
    pageNumber
  )
  for (const number of pagesToRender) {
    const disabled = number === '...'
    pageItems.push(
      <Pagination.Item
        key={`page-${number}`}
        active={number === pageNumber}
        disabled={disabled}
        onClick={() => handlePaginationChange(number)}
      >
        {number}
      </Pagination.Item>
    )
  }
  return (
    <>
      <span className='float-right'>
        <Pagination>
          <Pagination.Item
            key='page-start'
            onClick={() => handlePaginationChange(1)}
          >
            {'<<'}
          </Pagination.Item>
          <Pagination.Item
            key='page-back'
            onClick={() =>
              pageNumber > 1 && handlePaginationChange(pageNumber - 1)}
          >
            {'<'}
          </Pagination.Item>
          {pageItems}
          <Pagination.Item
            key='page-fwd'
            onClick={() =>
              pageNumber !== numberOfPages &&
              handlePaginationChange(pageNumber + 1)}
          >
            {'>'}
          </Pagination.Item>
          <Pagination.Item
            key='page-end'
            onClick={() => handlePaginationChange(numberOfPages)}
          >
            {'>>'}
          </Pagination.Item>
        </Pagination>
      </span>
      <span className='float-right'>
        <span style={{ margin: '1em' }}>
          Mostrando {count} de {total}
        </span>
      </span>
    </>
  )
}

export default EntitiesTablePagination
