import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { EntityFields } from '../../common/CRUD/EntityFields'
import { fields } from '../../../models/sales/packageModel'
import Button from 'react-bootstrap/Button'
import PackageComponents from './PackageComponents'
import OptionalComponent from '../../common/OptionalComponent'
import PropTypes from 'prop-types'

const PackagesDetails = (props) => {
  const {
    onAddClick,
    onPackageUpdated,
    targetPackage,
    onComponentRemoved,
    readOnly
  } = props

  return (
    <Row>
      <Col md={6}>
        <Row>
          <Col>
            <h3>Información</h3>
          </Col>
        </Row>
        <EntityFields
          readOnly={readOnly}
          fields={fields}
          isCreate
          entity={targetPackage}
          onChange={onPackageUpdated}
        />
      </Col>
      <Col>
        <Row>
          <Col>
            <h3>Componentes</h3>
          </Col>
          <OptionalComponent showComponent={readOnly !== true}>
            <Col className='text-right'>
              <Button variant='primary' onClick={onAddClick}>
                Añadir componente de paquete
              </Button>
            </Col>
          </OptionalComponent>
        </Row>
        <Row>
          <Col>
            <PackageComponents
              readOnly={readOnly}
              selectedComponents={targetPackage.components}
              onComponentRemove={onComponentRemoved}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

PackagesDetails.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  onPackageUpdated: PropTypes.func.isRequired,
  onComponentRemoved: PropTypes.func.isRequired,
  targetPackage: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired
}

export default PackagesDetails
