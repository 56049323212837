import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/sales/serviceModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const ServicesView = () => {
  const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
    '/api/sales/services'
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='servicio'
        entityNamePlural='servicios'
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

ServicesView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-services' href='/services' active>
    Servicios
  </Breadcrumb.Item>
)

export default ServicesView
