import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import { FaTrash } from 'react-icons/fa'
import { handleFocus } from '../../../utils/DOMEvents'

const styles = {
  formOverlay: {
    position: 'absolute',
    zIndex: 99,
    opacity: 0.5,
    backgroundColor: '#808080',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  }
}

const EditComponent = (props) => {
  const [state, setState] = useState({ isLoading: false, deleteMode: false })
  const { isLoading, error, deleteMode } = state

  const {
    show = false,
    component = {},
    onComponentUpdate,
    onComponentSave,
    onComponentRemove,
    onHide
  } = props

  const {
    concept = '',
    type = 'product',
    quantity = 0,
    cost,
    price = 0
  } = component
  const handleChange = ({ target }) => {
    onComponentUpdate(target.id, target.value)
  }

  const resetLoading = (stateOverrides = {}) =>
    setState((s) => ({ ...s, ...stateOverrides, isLoading: false }))

  const toggleDeleteMode = () =>
    setState((s) => ({ ...s, deleteMode: !deleteMode }))

  const handleComponentSave = () => {
    setState((s) => ({ ...s, isLoading: true }))
    if (deleteMode) {
      return onComponentRemove()
        .then(() => resetLoading({ deleteMode: false }))
        .catch(() => {
          resetLoading({ error: 'Ocurrió un error al borrar el componente' })
        })
    } else {
      onComponentSave()
        .then(resetLoading)
        .catch(() => {
          resetLoading({ error: 'Ocurrió un error al guardar el componente' })
        })
    }
  }

  const handleCancel = () => {
    resetLoading()
    if (deleteMode) {
      return toggleDeleteMode()
    }
    return onHide()
  }

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Editar componente </span>
            <span style={{ position: 'absolute', right: '2em' }}>
              <Button variant='danger' onClick={toggleDeleteMode}>
                <FaTrash />
              </Button>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant='danger'>{error}</Alert>}
          {isLoading && (
            <div
              style={styles.formOverlay}
              className='d-flex justify-content-center align-items-center'
            >
              <Spinner
                animation='border'
                style={{ width: '50px', height: '50px' }}
              />
            </div>
          )}
          {!deleteMode && (
            <Form>
              <Form.Group className='mb-1' controlId='quantity'>
                <Form.Label>Cantidad</Form.Label>
                <Form.Control
                  type='number'
                  value={quantity}
                  onChange={handleChange}
                  autoFocus
                  onFocus={handleFocus}
                />
              </Form.Group>
              <Form.Group className='mb-1' controlId='concept'>
                <Form.Label>Concepto</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={concept}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className='mb-1' controlId='type'>
                <Form.Label>Tipo</Form.Label>
                <Form.Select
                  aria-label='item type'
                  value={type}
                  onChange={handleChange}
                >
                  <option value='service'>Servicio</option>
                  <option value='product'>Producto</option>
                  <option value='package'>Paquete</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className='mb-1' controlId='cost'>
                <Form.Label>Costo</Form.Label>
                <Form.Control
                  type='number'
                  value={cost}
                  onChange={handleChange}
                  onFocus={handleFocus}
                />
              </Form.Group>
              <Form.Group className='mb-1' controlId='price'>
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  type='number'
                  value={price}
                  onChange={handleChange}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Form>
          )}
          {deleteMode && (
            <div>
              <>
                <h3>¿Realmente desea eliminar el siguiente componente?</h3>
                <h2 style={{ color: 'red' }}>Esto no se puede deshacer</h2>
                <pre>{JSON.stringify(component, undefined, 2)}</pre>
              </>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            type='submit'
            onClick={handleComponentSave}
            disabled={isLoading}
          >
            Guardar
          </Button>
          <Button
            variant='secondary'
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

EditComponent.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onComponentUpdate: PropTypes.func.isRequired,
  onComponentSave: PropTypes.func.isRequired,
  onComponentRemove: PropTypes.func.isRequired,
  component: PropTypes.shape({
    concept: PropTypes.string,
    edit: PropTypes.bool,
    type: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.string.isRequired
  })
}

export default EditComponent
