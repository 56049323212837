import React from 'react'
import Layout from './views/Layout'
import ErrorView from './views/ErrorView'

import adminRoutes from './routes/adminRoutes'
import financialResourcesRoutes from './routes/financialResourcesRoutes'
import humanResourcesRoutes from './routes/humanResourcesRoutes'
import materialResourcesRoutes from './routes/materialResourcesRoutes'
import restaurantsRoutes from './routes/restaurantsRoutes'
import salesRoutes from './routes/salesRoutes'

const routes = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorView />,
    handle: {
      crumb: Layout.breadcrumbs
    },
    children: [
      ...adminRoutes,
      ...financialResourcesRoutes,
      ...humanResourcesRoutes,
      ...materialResourcesRoutes,
      ...restaurantsRoutes,
      ...salesRoutes
    ]
  }
]

export default routes
