import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Código',
    property: 'code'
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  parseField({
    name: 'Locación',
    property: 'location',
    displayInTable: false
  })
]
