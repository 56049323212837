import { parseField } from '../../components/common/CRUD/Field'
import { parseFilter } from '../../components/common/CRUD/Filter'
import TagSchemaClassPicker from '../../components/financialResources/transactions/TagSchemaClassPicker'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { get } from 'lodash'
import { NumericFormat } from 'react-number-format'

export const fields = [
  parseField({
    property: 'id',
    name: 'ID',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    property: 'date',
    name: 'Fecha',
    type: 'date'
  }),
  parseField({
    property: 'description',
    name: 'Descripción'
  }),
  parseField({
    property: 'amount',
    name: 'Monto',
    type: 'number',
    formatter: (amount) => (
      <NumericFormat
        value={amount}
        prefix='$'
        displayType='text'
        thousandsGroupStyle='thousands'
        thousandSeparator=','
      />
    )
  }),
  parseField({
    name: 'Categoría',
    type: 'custom',
    render (entity) {
      const classes = entity.classes || []
      const assignedClass = classes.reduce(
        (classes, currentClass) => `${classes} ${currentClass.name}`,
        ''
      )
      return assignedClass || 'Sin Clasificar'
    },
    renderEdit ({ entity, onChange, loaderData }) {
      const { account } = loaderData
      const transaction = {
        ...entity,
        className: get(entity, 'className', get(entity, 'classes[0].name', ''))
      }
      return (
        <Row key='class-picker'>
          <Col>
            <Form.Label>Categoría</Form.Label>
            <TagSchemaClassPicker
              transaction={transaction}
              creditClasses={account.isCreditCard}
              onTransactionUpdate={(transaction, field, newValue) => {
                onChange('className', newValue)
              }}
            />
          </Col>
        </Row>
      )
    }
  })
]

export const filters = [
  parseFilter({
    filterBy: 'startDate',
    label: 'Fecha Inicial',
    targetField: 'date',
    filterType: 'date',
    operator: 'gte'
  }),
  parseFilter({
    filterBy: 'endDate',
    label: 'Fecha Final',
    targetField: 'date',
    filterType: 'date',
    operator: 'lte'
  }),
  parseFilter({
    filterBy: 'className',
    filterType: 'custom',
    targetField: '$classes.name$',
    render: (onFilterChange, filterValue, loaderData) => {
      const { account } = loaderData
      return (
        <Row key='class-picker'>
          <Col>
            <Form.Label>Etiqueta</Form.Label>
            <TagSchemaClassPicker
              transaction={{ className: filterValue }}
              creditClasses={account.isCreditCard}
              onTransactionUpdate={(transaction, field, newValue) =>
                onFilterChange('className', newValue)}
            />
          </Col>
        </Row>
      )
    }
  })
]
