import { getAxios } from '../utils/requests'
const axios = getAxios()

export const createCRUDService = (basePath) => {
  const getItem = (id) => {
    return axios.get(`${basePath}/${id}`).then((response) => response.data)
  }
  const listItems = (params = {}) => {
    return axios.get(basePath, { params }).then((response) => response.data)
  }

  const updateItem = (item) => {
    return axios.put(`${basePath}/${item.id}`, item)
  }

  const createItem = (item) => {
    // temp hack until we move users into users
    return axios.post(`${basePath}`, { ...item })
  }

  const deleteItem = (item) => {
    return axios.delete(`${basePath}/${item.id}`)
  }
  return {
    getItem,
    listItems,
    updateItem,
    createItem,
    deleteItem
  }
}
