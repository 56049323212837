import React from 'react'
import PropTypes from 'prop-types'
import { Toast } from 'react-bootstrap'

const levelDictionary = {
  error: 'danger',
  message: 'primary'
}

const AlertMessage = (props) => {
  const { level, message } = props
  const variant = levelDictionary[level] || level
  return (
    <Toast className='d-inline-block m-1' bg={variant.toLowerCase()}>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  )
}

AlertMessage.propTypes = {
  level: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default AlertMessage
