import dayjs from 'dayjs'

const parseDateWithSlashes = (date) => {
  const [day, month, year] = date.split('/')
  const parsedDate = dayjs(new Date(`20${year}`, parseInt(month) - 1, day))
  return parsedDate.format('YYYY-MM-DD')
}

export const parseRecordDate = (recordDate) => {
  if (recordDate.length === 8 && (recordDate.indexOf('/') === 2)) {
    return parseDateWithSlashes(recordDate)
  }
  // we setup the first month as null so that the first month will be in index 1
  const months = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ]
  const day = recordDate.slice(0, 2)
  const month = recordDate.slice(3, 6)
  const monthNumber = months.indexOf(month.toLowerCase())
  let year = recordDate.slice(7, 11)
  if (year.length === 2) year = `20${year}`
  const parsedDate = dayjs(new Date(year, monthNumber, day))
  return parsedDate.format('YYYY-MM-DD')
}
