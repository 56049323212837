import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/sales/quoteModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const QuotesView = () => {
  const { listItems, deleteItem, createItem, updateItem } =
    createCRUDService('/api/sales/quotes')
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='Cotización'
        entityNamePlural='Cotizaciones'
        showAllInList
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

QuotesView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-clients' href='/sales/quotes' active>
    Cotizaciones
  </Breadcrumb.Item>
)

export default QuotesView
