import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import CRUDModal from './CRUDModal'
import { capitalize } from '../../../utils/strings'
import FetchMessage from './FetchMessage'
import { getDefaultEntity } from '../../../utils/entities'
import EntityTableFilters from './EntityTableFilters'
import OptionalComponent from '../OptionalComponent'
import EntitiesList from './EntitiesList'
import EntitiesTable from './EntitiesTable'
import { FaUndoAlt } from 'react-icons/fa'

function resetStateFactory (defaultState, setState) {
  return function (overrides = {}) {
    setState({ ...defaultState, ...overrides })
  }
}

const defaultState = {
  entity: {},
  paginationParams: { limit: 50, offset: 0 },
  isFetching: false,
  action: null,
  reload: false,
  data: { rows: [] }
}

const CRUD = (props) => {
  const {
    fields,
    filters = [],
    entityName,
    entityNamePlural,
    customTableTitle,
    dataSource,
    onCreate,
    onUpdate,
    paginated = false,
    onDelete,
    listTitle,
    showAllInList = false,
    onClick,
    createButtonLabel,
    extraActions,
    disableCreate = false,
    useTable = false,
    customModal,
    loaderData = {}
  } = props
  const [state, setState] = useState({
    ...defaultState,
    filterParams: filters,
    sortOrder: ''
  })
  const {
    action,
    entity,
    isFetching,
    data,
    paginationParams,
    filterParams,
    reload,
    sortOrder
  } = state

  const resetStateFunction = resetStateFactory(
    { ...defaultState, paginationParams, filterParams },
    setState
  )
  const parseFilterValue = (currentFilter) => {
    const { filterValue, operator, targetField } = currentFilter
    return `${targetField}:${operator}:${filterValue}`
  }
  const refreshData = (pagination, filterParams = []) => {
    const filters = filterParams.reduce((allFilters, currentFilter) => {
      const parsedValue = parseFilterValue(currentFilter)
      allFilters.push(parsedValue)
      return allFilters
    }, [])
    setState((s) => ({ ...s, isFetching: true }))
    dataSource({ ...pagination, filters })
      .then((data) => {
        const newState = {
          isFetching: false,
          data: Array.isArray(data) ? { rows: data } : data
        }
        setState((s) => ({ ...s, ...newState }))
      })
      .catch((ex) => {
        console.log('ex', ex)
        console.error('Error loading dataSource in CRUD component')
      })
  }
  const refreshDataCallback = useCallback(refreshData, [dataSource])

  useEffect(() => {
    refreshDataCallback(paginationParams, filterParams)
  }, [paginationParams, filterParams, refreshDataCallback, reload])

  const triggerReload = () => {
    setState((s) => ({ ...s, reload: !reload }))
  }

  const showEdit = (entity) => {
    resetStateFunction({
      action: 'update',
      entity,
      data
    })
  }

  const onClickHandler = onClick || showEdit

  const showCreate = () => {
    resetStateFunction({
      action: 'create',
      entity: getDefaultEntity(fields),
      data
    })
  }

  const onCreateOrUpdateHandler = async (entity, action) => {
    if (action === 'create') await onCreate(entity)
    if (action === 'update') await onUpdate(entity)
    resetStateFunction({ reload: !state.reload, data })
  }

  const onDeleteHandler = async (entity) => {
    await onDelete(entity)
    resetStateFunction({ reload: !state.reload, data })
  }

  const onCancelHandler = () => {
    resetStateFunction({ data })
  }

  const onFilterChange = (target, value) => {
    setState({
      ...state,
      filterParams: state.filterParams.map((filter) => {
        let { filterBy, filterValue } = filter
        if (filterBy === target) filterValue = value
        return {
          ...filter,
          filterValue
        }
      })
    })
  }

  const compareFields = (fieldA, fieldB, fieldType = 'text', order = 'ASC') => {
    const valueA = fieldType === 'number' ? parseFloat(fieldA) : fieldA
    const valueB = fieldType === 'number' ? parseFloat(fieldB) : fieldB
    if (valueB > valueA) return (order === 'ASC') ? 1 : -1
    if (valueB < valueA) return (order === 'ASC') ? -1 : 1
    return 0
  }

  const handleSortChange = (sortColumn, columnType) => {
    const order = (sortColumn === sortOrder) ? 'ASC' : 'DESC'
    const sortedData = data.rows.sort((a, b) => {
      const propA = get(a, sortColumn)
      const propB = get(b, sortColumn)
      return compareFields(propA, propB, columnType, order)
    })
    const prefix = (sortColumn === sortOrder) ? '-' : ''
    setState(() => ({ ...state, data: { ...data, rows: sortedData }, sortOrder: `${prefix}${sortColumn}` }))
  }

  const plural = entityNamePlural || `${entityName}s`
  const tableTitle = customTableTitle || plural
  const createButtonText = createButtonLabel || `Crear nuevo ${entityName}`
  return (
    <main style={{ padding: '1rem 0' }}>
      <h2>{capitalize(tableTitle)}</h2>
      {disableCreate === false && (
        <Button style={{ marginBottom: '1em', marginRight: '1em' }} onClick={showCreate}>
          {createButtonText}
        </Button>
      )}
      <Button style={{ marginBottom: '1em' }} onClick={triggerReload}>
        <FaUndoAlt />
      </Button>
      <br />
      <OptionalComponent showComponent={filterParams.length > 0}>
        <EntityTableFilters
          onFilterChange={onFilterChange}
          filterParams={filterParams}
          loaderData={loaderData}
        />
        <br />
      </OptionalComponent>

      {(useTable === false) && (
        <EntitiesList
          data={data}
          subTitleProp='client.name'
          onClick={onClickHandler}
          titleProp={listTitle || 'name'}
          showAllInList={showAllInList}
          fields={fields}
          extraActions={extraActions}
        />
      )}

      {(useTable === true) && (
        <>
          <EntitiesTable
            paginated={paginated}
            showEdit={onClickHandler}
            extraActions={extraActions}
            data={data}
            fields={fields}
            handleSortChange={handleSortChange}
            onPaginationChange={(paginationParams) =>
              setState({ ...state, paginationParams })}
          />
        </>
      )}

      <FetchMessage isFetching={isFetching} plural={plural} />
      {customModal && (
        customModal({
          action,
          entity,
          entityName,
          loaderData,
          onCreateOrUpdateHandler,
          onDeleteHandler,
          onCancelHandler
        })
      )}
      {!customModal && (
        <CRUDModal
          action={action}
          entity={entity}
          entityName={entityName}
          loaderData={loaderData}
          entityNamePlural={entityNamePlural}
          fields={fields}
          onSubmit={onCreateOrUpdateHandler}
          onDelete={onDeleteHandler}
          onCancel={onCancelHandler}
        />
      )}
    </main>
  )
}

CRUD.propTypes = {
  fields: PropTypes.array.isRequired,
  filters: PropTypes.array,
  listTitle: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  entityNamePlural: PropTypes.string,
  customTableTitle: PropTypes.string,
  paginated: PropTypes.bool,
  dataSource: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  showAllInList: PropTypes.bool,
  createButtonLabel: PropTypes.string,
  extraActions: PropTypes.func,
  disableCreate: PropTypes.bool,
  useTable: PropTypes.bool,
  loaderData: PropTypes.any,
  customModal: PropTypes.func
}

export default CRUD
