import React from 'react'

export default function ColumnHeaders (props) {
  const { fields, handleSortChange } = props
  const fieldsToRender = fields.filter((f) => f.displayInTable)
  return fieldsToRender.map((field) => {
    return (
      <th
        key={`header-${field.name}`} onClick={() => {
          if (handleSortChange) handleSortChange(field.property, field.type)
        }}
      >{field.name}
      </th>
    )
  })
}
