export const parseField = (field) => {
  return {
    name: field.name,
    property: field.property || field.name,
    type: field.type || 'text',
    placeHolder: field.placeHolder || '',
    editDisabled: field.editDisabled || false,
    insertDisabled: field.insertDisabled || false,
    displayInTable: field.displayInTable !== false,
    displayInList: field.displayInList !== true,
    render: field.render,
    renderEdit: field.renderEdit,
    options: field.options,
    values: field.values,
    formatter: field.formatter
  }
}
