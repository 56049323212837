import React, { useCallback, useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import ReportOptionsForm from '../../components/financialResources/reports/ReportOptionsForm'
import TransactionsTable from '../../components/financialResources/reports/TransactionsTable'
import QuickTransaction from '../../components/financialResources/transactions/QuickTransaction'
import { listTransactions } from '../../services/transactionsService'
import Col from 'react-bootstrap/Col'
import dayjs from 'dayjs'
import Totals from '../../components/financialResources/reports/Totals'
import DebitBalanceDetailReport from '../../components/financialResources/reports/DebitBalanceDetailReport'
import { Link } from 'react-router-dom'
import TransactionsByClassPie from '../../components/financialResources/reports/TransactionsByClassPie'
import Row from 'react-bootstrap/Row'

const DebitReportView = (props) => {
  const today = new Date()
  const defaultStartDate = new Date().setDate(1)
  const defaultEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const [state, setState] = useState({
    transactions: [],
    transactionsSort: 'date',
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    showQuickTransaction: false,
    refresh: 0,
    transactionsClass: ''
  })

  const {
    startDate,
    endDate,
    showQuickTransaction,
    refresh,
    transactionsClass,
    // groupBy = 'week',
    transactions = [],
    transactionsSort,
    bankAccountId
  } = state

  const sortBy = (column) => {
    const sortWithDir =
      column === transactionsSort ? `-${transactionsSort}` : column
    setState((s) => ({ ...s, transactionsSort: sortWithDir }))
  }

  const loadTransactions = useCallback(
    (className, columnSortParam = 'date') => {
      // We will default to descending and toggle to ascending
      const sortDir = columnSortParam[0] === '-' ? 'asc' : 'desc'
      const columnSortBy =
        sortDir === 'asc' ? columnSortParam.slice(1) : columnSortParam
      listTransactions(
        className,
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).format('YYYY-MM-DD'),
        columnSortBy,
        sortDir,
        bankAccountId,
        false
      ).then((sortedTransactions) => {
        setState((s) => ({ ...s, transactions: sortedTransactions }))
      })
    },
    // disabling eslint because this enables a refresh after creating a new transaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, bankAccountId, refresh]
  )

  useEffect(() => {
    loadTransactions(transactionsClass, transactionsSort)
  }, [transactionsClass, transactionsSort, loadTransactions])

  const optionsChange = (field, value) => {
    console.log('Options changed!', field, value)
    setState((s) => ({ ...s, [field]: value }))
  }

  const toggleQuickTransaction = (refreshData) => {
    const newState = {
      ...state,
      showQuickTransaction: !showQuickTransaction
    }
    if (refreshData === true) newState.refresh = refresh + 1
    setState(newState)
  }

  const refreshData = () => {
    setState((s) => ({ ...s, refresh: refresh + 1 }))
  }

  const closeQuickTransaction = () => {
    toggleQuickTransaction(true)
  }

  // const classSelected = (className) => {
  //   setState((s) => ({ ...s, transactionsClass: className }))
  // }

  return (
    <div>
      <div className='pb-3'>
        <ReportOptionsForm
          onOptionsChange={optionsChange}
          startDate={startDate}
          endDate={endDate}
          isCreditCard={false}
          refreshData={refreshData}
        />
      </div>
      <div style={{ height: '80vh', overflowY: 'scroll', padding: '0 1em' }}>
        <div>
          <Totals
            startDate={startDate}
            endDate={endDate}
            refresh={refresh}
            bankAccountId={bankAccountId}
          />
        </div>
        <div>
          <Row>
            <Col>
              <h3>Distribución por etiqueta</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <TransactionsByClassPie
                bankAccountId={bankAccountId}
                startDate={startDate}
                endDate={endDate}
                refresh={refresh}
                isCreditCard={false}
              />
            </Col>
            <Col>
              <TransactionsByClassPie
                bankAccountId={bankAccountId}
                startDate={startDate}
                endDate={endDate}
                refresh={refresh}
                isCreditCard={false}
                negativeTransactions
              />
            </Col>
          </Row>
        </div>
        <div>
          <DebitBalanceDetailReport
            startDate={startDate}
            endDate={endDate}
            refresh={refresh}
            bankAccountId={bankAccountId}
          />
        </div>
        <div>
          <QuickTransaction
            showQuickTransaction={showQuickTransaction}
            toggleModal={toggleQuickTransaction}
            onSave={closeQuickTransaction}
            isCreditCard={false}
          />
        </div>
        <div>
          <Col>
            <h1>Transacciones</h1>
            <TransactionsTable
              transactions={transactions}
              sortBy={sortBy}
              onQuickTransactionSave={refreshData}
              isCreditCard={false}
            />
          </Col>
        </div>
      </div>
    </div>
  )
}

DebitReportView.breadcrumbs = () => {
  return [
    <Breadcrumb.Item key='breadcrumb-reports' linkAs='span'>
      <Link to='/financial_resources/reports'>Reportes</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-reports' active>
      Reporte de débito
    </Breadcrumb.Item>
  ]
}

export default DebitReportView
