import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  fields,
  getCatalogueActions
} from '../../../models/sales/serviceModel'
import EntitiesTable from '../../common/CRUD/EntitiesTable'
import { createCRUDService } from '../../../services/crudService'
const { listItems } = createCRUDService('/api/sales/services')

const ServicesCatalogue = (props) => {
  const [state, setState] = useState({ services: { rows: [] } })
  const { services } = state
  const { onServiceAdd } = props

  const onAddCallback = (item) => {
    onServiceAdd({ ...item, type: 'service' })
  }

  const extraActions = getCatalogueActions({ onAddCallback })

  useEffect(() => {
    listItems()
      .then((responseServices) =>
        setState((s) => ({ ...s, services: responseServices }))
      )
      .catch((error) => setState((s) => ({ ...s, error })))
  }, [])

  return (
    <>
      <EntitiesTable
        paginated
        useEdit={false}
        useDelete={false}
        extraActions={extraActions}
        data={services}
        fields={fields}
        onPaginationChange={(paginationParams) =>
          setState((s) => ({ ...s, paginationParams }))}
      />
    </>
  )
}

ServicesCatalogue.propTypes = {
  onServiceAdd: PropTypes.func.isRequired
}

export default ServicesCatalogue
