import { getAxios } from '../utils/requests'
const axios = getAxios()

export default class TagSchemaClassService {
  constructor (schemaId) {
    if (!schemaId) throw new Error('This service needs an schemaId to work!')
    this.schemaId = schemaId
  }

  getUrl (schemaClassId = '') {
    const { schemaId } = this
    const baseUrl = `/api/financial_resources/tag_schemas/${schemaId}/classes`
    return `${baseUrl}/${schemaClassId}`
  }

  async getSchemaClasses () {
    const result = await axios.get(this.getUrl())
    return result.data
  }

  createSchemaClass (details) {
    const { schemaId } = this
    const schemaClass = { ...details, schemaId }
    return axios.post(this.getUrl(), schemaClass)
  }

  updateSchemaClass (details) {
    const { schemaId } = this
    const schemaClass = { ...details, schemaId }
    return axios.put(this.getUrl(details.id), schemaClass)
  }

  deleteSchemaClass (schemaClassId) {
    return axios.delete(this.getUrl(schemaClassId))
  }

  static getAllSchemaClasses (byName = false, schema) {
    const schemaName = schema || 'ALL'
    return axios
      .get(`/api/financial_resources/tag_schemas/classes/${schemaName}`)
      .then((response) => response.data)
      .then((schemaClasses = []) => {
        return schemaClasses.reduce((orderedSchemaClasses, nextItem) => {
          if (byName) {
            orderedSchemaClasses[nextItem.name] = nextItem
          } else {
            orderedSchemaClasses[nextItem.id] = nextItem
          }
          return orderedSchemaClasses
        }, {})
      })
  }
}
