import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Nombre',
    property: 'firstName'
  }),
  parseField({
    name: 'Apellido',
    property: 'lastName'
  }),
  parseField({
    name: 'Número de empleado',
    property: 'employeeNumber',
    displayInTable: false
  }),
  parseField({
    name: 'Día de inicio',
    property: 'startDate',
    type: 'date',
    displayInTable: false
  })
]
