import * as Papa from 'papaparse'
import { v4 as uuid } from 'uuid'
import { parseRecordDate } from './dates'

export const processFile = (fileRef, callback) => {
  return new Promise((resolve, reject) => {
    if (
      !fileRef ||
      !fileRef.current ||
      !fileRef.current.files ||
      !fileRef.current.files[0]
    ) { return }

    const reader = new FileReader()
    reader.addEventListener('error', reject)
    reader.addEventListener('load', (e) => {
      const text = e.currentTarget.result
      const result = Papa.parse(text, { header: true })
      // We skip entries without amount, trick to handle last empty line
      const records = result.data.filter((entry) => entry.amount)
      const parsedRecords = records.map((record) => {
        return {
          description: record.description.substring(0, 49),
          uuid: uuid(),
          date: parseRecordDate(record.date),
          amount: record.amount.replace('$', '').replace(',', ''),
          class: record.class || ''
        }
      })
      resolve(parsedRecords)
    })
    reader.readAsText(fileRef.current.files[0])
  })
}
