import dayjs from 'dayjs'
import { getAxios } from '../utils/requests'
const axios = getAxios()

export default class TransactionsService {
  constructor(bankAccountId) {
    if (!bankAccountId)
      throw new Error('This service needs an bankAccountId to work!')
    this.bankAccountId = bankAccountId
  }

  getUrl(transactionId = '') {
    const { bankAccountId } = this
    const baseUrl = `/api/financial_resources/bank_accounts/${bankAccountId}/transactions`
    return `${baseUrl}/${transactionId}`
  }

  async getTransactions() {
    const result = await axios.get(this.getUrl())
    return result.data
  }

  updateTransaction(transaction) {
    return axios.put(this.getUrl(transaction.id), transaction)
  }

  createTransaction(details) {
    const { bankAccountId } = this
    const transaction = { ...details, bankAccountId }
    return axios.post(this.getUrl(), transaction)
  }

  createTransactions(transactions) {
    return axios.post(this.getUrl('bulk'), transactions)
  }

  deleteTransaction(transactionId) {
    return axios.delete(this.getUrl(transactionId))
  }
  createTransfer(transferData) {
    return axios.post(this.getUrl('transfer'), transferData)
  }
}

export const listTransactions = async (
  className,
  startDate,
  endDate,
  sortBy,
  sortDir,
  bankAccountId,
  isCreditCard = false
) => {
  const filters = [`$classes.name$:eq:${className}`]
  if (startDate) filters.push(`date:gte:${startDate}`)
  if (endDate) filters.push(`date:lte:${endDate}`)
  if (bankAccountId) filters.push(`bankAccountId:eq:${bankAccountId}`)

  const { data } = await axios.get(
    '/api/financial_resources/bank_accounts/transactions',
    {
      params: {
        filters,
        isCreditCard
      }
    }
  )
  if (sortBy && sortDir) {
    return sortTransactions(data, sortBy, sortDir)
  }
  return data
}

export const sortByFullDate = (previous, current, order = 'desc') => {
  const previousDate = dayjs(previous)
  const currentDate = dayjs(current)
  const difference = currentDate.diff(previousDate)
  if (order === 'desc') return difference
  return difference === 0 ? difference : difference * -1
}
export const sortByAmount = (previous, current, order = 'desc') => {
  if (previous === current) return 0

  // This is the way for ascending values
  let larger = 1
  let smaller = -1
  if (order === 'asc') {
    larger = larger * -1
    smaller = smaller * -1
  }

  const previousFloat = parseFloat(previous)
  const currentFloat = parseFloat(current)

  // We want to order descending for now
  if (previousFloat < currentFloat) return larger
  return smaller
}

export const sortTransactions = (
  transactions,
  sortBy = 'amount',
  sortDir = 'desc'
) => {
  const { rows } = transactions
  const sorter = sortBy === 'amount' ? sortByAmount : sortByFullDate
  try {
    return rows.sort((previous, current) => {
      const valueA = previous[sortBy]
      const valueB = current[sortBy]
      return sorter(valueA, valueB, sortDir)
    })
  } catch (err) {
    console.error(
      'Error sorting the transactions, handling error to not crash whole app'
    )
    console.error(err)
  }
}
