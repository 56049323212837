import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/financialResources/supplierModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const SuppliersView = () => {
  const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
    '/api/financial_resources/suppliers'
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='Proveedor'
        entityNamePlural='Proveedores'
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

SuppliersView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-suppliers' href='/suppliers' active>
    Proveedores
  </Breadcrumb.Item>
)

export default SuppliersView
