import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import DatePicker from '../../common/CRUD/DatePicker'
import Spinner from 'react-bootstrap/Spinner'
import { handleFocus } from '../../../utils/DOMEvents'
import { FaTrash } from 'react-icons/fa'
import Alert from 'react-bootstrap/Alert'
import BankAccountPicker from '../../financialResources/bank_accounts/BankAccountPicker'
const styles = {
  formOverlay: {
    position: 'absolute',
    zIndex: 99,
    opacity: 0.5,
    backgroundColor: '#808080',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  }
}

const NewPaymentModal = (props) => {
  const {
    show,
    onCancel,
    onConfirm,
    payment,
    onPaymentUpdate,
    handleDeletePayment
  } = props
  const { type, notes, transaction = {} } = payment
  const { bankAccountId, amount } = transaction

  const [state, setState] = useState({ isLoading: false, deleteMode: false })
  const { isLoading, deleteMode, error } = state
  const isUpdate = payment.id !== undefined
  const title = isUpdate ? 'Actualizar Pago' : 'Nuevo Pago'
  const setLoading = (loading = false) =>
    setState((s) => ({ ...s, isLoading: loading, deleteMode: false }))
  const toggleDeleteMode = () =>
    setState((s) => ({ ...s, deleteMode: !deleteMode }))
  const handleCancel = () => {
    if (deleteMode) {
      toggleDeleteMode()
    } else {
      onCancel()
    }
  }
  const handleConfirm = () => {
    setLoading(true)
    if (deleteMode) {
      handleDeletePayment(payment)
        .then(setLoading)
        .catch(() => {
          setState((s) => ({
            ...s,
            error: 'Error al borrar pago',
            isLoading: false
          }))
        })
    } else {
      onConfirm()
        .then(setLoading)
        .catch(() => {
          setState((s) => ({
            ...s,
            error: 'Error al guardar pago',
            isLoading: false
          }))
        })
    }
  }
  const handleAccountUpdate = (newValue) =>
    onPaymentUpdate({ target: { id: 'transaction.bankAccountId', value: newValue } })

  return (
    <Modal
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <span>{title}</span>
          <span style={{ position: 'absolute', right: '2em' }}>
            <Button variant='danger' onClick={toggleDeleteMode}>
              <FaTrash />
            </Button>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant='danger'>{error}</Alert>}
        {isLoading && (
          <div
            style={styles.formOverlay}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner
              animation='border'
              style={{ width: '50px', height: '50px' }}
            />
          </div>
        )}
        {!deleteMode && (
          <Form>
            <Form.Group className='mb-1' controlId='bankAccountId'>
              <Form.Label>Cuenta</Form.Label>
              <BankAccountPicker isCreditCard={false} onUpdate={handleAccountUpdate} initialAccountId={bankAccountId} />
            </Form.Group>
            <Form.Group className='mb-1' controlId='transaction.amount'>
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type='number'
                value={amount}
                onChange={onPaymentUpdate}
                autoFocus
                onFocus={handleFocus}
              />
            </Form.Group>
            <Form.Group className='mb-1' controlId='type'>
              <Form.Label>Tipo de Pago</Form.Label>
              <Form.Select
                aria-label='payment type'
                value={type}
                onChange={onPaymentUpdate}
              >
                <option value='cash'>Efectivo</option>
                <option value='card'>Tarjeta</option>
                <option value='credit'>Crédito</option>
                <option value='other'>Otros</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className='mb-1' controlId='notes'>
              <Form.Label>Notas</Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={3}
                placeholder=''
                value={notes}
                onChange={onPaymentUpdate}
                onFocus={handleFocus}
              />
            </Form.Group>
            <Form.Group className='mb-1' controlId='notes'>
              <Form.Label />
              <DatePicker
                field={{ name: 'Fecha', property: 'date' }}
                label='Fecha'
                entity={payment}
                onChange={(field, value) => {
                  onPaymentUpdate({ target: { id: field, value } })
                }}
              />
            </Form.Group>
          </Form>
        )}
        {deleteMode && (
          <div>
            <>
              <h3>¿Realmente desea eliminar el siguiente pago?</h3>
              <h2 style={{ color: 'red' }}>Esto no se puede deshacer</h2>
              <pre>{JSON.stringify(payment, undefined, 2)}</pre>
            </>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} variant='primary' disabled={isLoading}>
          Confirmar
        </Button>
        <Button onClick={handleCancel} variant='secondary' disabled={isLoading}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

NewPaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onPaymentUpdate: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    transaction: PropTypes.shape({
      bankAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  }).isRequired,
  handleDeletePayment: PropTypes.func.isRequired
}

export default NewPaymentModal
