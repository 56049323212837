import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

const InventoryOutView = () => {
  return (
    <div>
      <h1>Inventory Out!</h1>
    </div>
  )
}

InventoryOutView.breadcrumbs = (params) => {
  const { companyId, inventoryId } = params
  return [
    <Breadcrumb.Item key='breadcrumb-companies' href='/companies'>
      Companies
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-company-id' active>
      {companyId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item
      key='breadcrumb-inventories'
      href={`/companies/${companyId}/inventories`}
    >
      Inventories
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventory-id' active>
      {inventoryId}
    </Breadcrumb.Item>,
    <Breadcrumb.Item
      key='breadcrumb-inventories-stock'
      href={`/companies/${companyId}/inventories/${inventoryId}/stock`}
    >
      Stock
    </Breadcrumb.Item>,
    <Breadcrumb.Item key='breadcrumb-inventories-out' active>
      Out
    </Breadcrumb.Item>
  ]
}

export default InventoryOutView
