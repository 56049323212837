import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { NumericFormat } from 'react-number-format'

const styles = {
  header: {
    position: 'relative',
    padding: '.5em'
  }
}

const paymentTypes = {
  cash: 'Efectivo',
  card: 'Tarjeta',
  credit: 'Crédito',
  other: 'Otros'
}
const PaymentsList = (props) => {
  const { payments = [], onPaymentEdit } = props
  return (
    <>
      {payments.map((payment, index) => {
        const { date, transaction, type, notes, key } = payment
        const { amount } = transaction
        const paymentType = paymentTypes[type] || paymentTypes.other
        return (
          <Card key={key} style={{ textAlign: 'center' }} className='mb-3'>
            <Card.Header style={styles.header}>
              {date.replaceAll('-', '/')}
            </Card.Header>
            <Card.Body onClick={() => onPaymentEdit(payment)}>
              Cantidad:
              <NumericFormat
                value={amount}
                prefix='$'
                displayType='text'
                thousandsGroupStyle='thousands'
                thousandSeparator=','
              />
              <br />
              Tipo: {paymentType}
              <br />
              Notas: {notes}
            </Card.Body>
          </Card>
        )
      })}
    </>
  )
}

PaymentsList.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      transaction: PropTypes.shape({
        bankAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      }),
      notes: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onPaymentEdit: PropTypes.func.isRequired
}

export default PaymentsList
