import React from 'react'
import InventoriesView from '../views/materialResources/InventoriesView'
import InventoryStockView from '../views/materialResources/InventoryStockView'
import InventoryInView from '../views/materialResources/InventoryInView'
import InventoryOutView from '../views/materialResources/InventoryOutView'
import ItemsVIew from '../views/materialResources/ItemsView'
import StockroomsView from '../views/materialResources/StockroomsView'

const routes = [
  {
    path: 'material_resources/companies/:companyId/inventories',
    element: <InventoriesView />,
    handle: {
      crumb: InventoriesView.breadcrumbs
    }
  },
  {
    path: 'material_resources/companies/:companyId/inventories/:inventoryId/stock',
    element: <InventoryStockView />,
    handle: {
      crumb: InventoryStockView.breadcrumbs
    }
  },
  {
    path: 'material_resources/companies/:companyId/inventories/:inventoryId/in',
    element: <InventoryInView />,
    handle: {
      crumb: InventoryInView.breadcrumbs
    }
  },
  {
    path: 'material_resources/companies/:companyId/inventories/:inventoryId/out',
    element: <InventoryOutView />,
    handle: {
      crumb: InventoryOutView.breadcrumbs
    }
  },
  {
    path: 'material_resources/items',
    element: <ItemsVIew />,
    handle: {
      crumb: ItemsVIew.breadcrumbs
    }
  },
  {
    path: 'material_resources/stockrooms',
    element: <StockroomsView />,
    handle: {
      crumb: StockroomsView.breadcrumbs
    }
  }
]

export default routes
