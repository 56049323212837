import React from 'react'
import CompaniesView from '../views/sales/CompaniesView'
import ClientsView from '../views/sales/ClientsView'
import PackagesView from '../views/sales/PackagesView'
import PackageManagementView from '../views/sales/PackageManagementView'
import ProductsView from '../views/sales/ProductsView'
import ServicesView from '../views/sales/ServicesView'
import ProjectsView from '../views/sales/ProjectsView'
import ProjectManagementView from '../views/sales/ProjectManagementView'
import QuotesView from '../views/sales/QuotesView'

const routes = [
  {
    path: 'sales/companies',
    element: <CompaniesView />,
    handle: {
      crumb: CompaniesView.breadcrumbs
    }
  },
  {
    path: 'sales/clients',
    element: <ClientsView />,
    handle: {
      crumb: ClientsView.breadcrumbs
    }
  },
  {
    path: 'sales/packages',
    element: <PackagesView />,
    handle: {
      crumb: PackagesView.breadcrumbs
    }
  },
  {
    path: 'sales/packages/create',
    element: <PackageManagementView />,
    handle: {
      crumb: PackageManagementView.breadcrumbs
    }
  },
  {
    path: 'sales/packages/:action/:id',
    element: <PackageManagementView />,
    handle: {
      crumb: PackageManagementView.breadcrumbs
    }
  },
  {
    path: 'sales/products',
    element: <ProductsView />,
    handle: {
      crumb: ProductsView.breadcrumbs
    }
  },
  {
    path: 'sales/services',
    element: <ServicesView />,
    handle: {
      crumb: ServicesView.breadcrumbs
    }
  },
  {
    path: 'sales/projects',
    element: <ProjectsView />,
    handle: {
      crumb: ProjectsView.breadcrumbs
    }
  },
  {
    path: 'sales/projects/:id',
    element: <ProjectManagementView />,
    handle: {
      crumb: ProjectManagementView.breadcrumbs
    }
  },
  {
    path: 'sales/quotes',
    element: <QuotesView />,
    handle: {
      crumb: QuotesView.breadcrumbs
    }
  }
]
export default routes
