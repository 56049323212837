import React, { useEffect, useState } from 'react'
import {
  fields,
  getCatalogueActions
} from '../../../models/sales/productModel'
import EntitiesTable from '../../common/CRUD/EntitiesTable'
import { createCRUDService } from '../../../services/crudService'
import PropTypes from 'prop-types'
const { listItems } = createCRUDService('/api/sales/products')

const ProductsCatalogue = (props) => {
  const [state, setState] = useState({ products: { rows: [] } })
  const { products } = state
  const { onProductAdd } = props

  const onAddCallback = (item) => {
    onProductAdd({ ...item, type: 'product' })
  }
  const extraActions = getCatalogueActions({ onAddCallback })

  useEffect(() => {
    listItems()
      .then((responseProducts) =>
        setState((s) => ({ ...s, products: responseProducts }))
      )
      .catch((error) => setState((s) => ({ ...s, error })))
  }, [])

  return (
    <>
      <EntitiesTable
        paginated
        useEdit={false}
        useDelete={false}
        extraActions={extraActions}
        data={products}
        fields={fields}
        onPaginationChange={(paginationParams) =>
          setState((s) => ({ ...s, paginationParams }))}
      />
    </>
  )
}

ProductsCatalogue.propTypes = {
  onProductAdd: PropTypes.func.isRequired
}

export default ProductsCatalogue
