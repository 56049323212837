import React from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { FaMinusCircle } from 'react-icons/fa'
import OptionalComponent from '../../common/OptionalComponent'
import PropTypes from 'prop-types'

const styles = {
  centered: {
    textAlign: 'center'
  }
}
const PackageComponents = (props) => {
  const {
    selectedComponents = [],
    onComponentRemove,
    readOnly = false
  } = props
  return (
    <>
      <Table striped style={styles.centered}>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nombre</th>
            <th>Tipo</th>
            <OptionalComponent showComponent={readOnly !== true}>
              <th>Eliminar</th>
            </OptionalComponent>
          </tr>
        </thead>
        <tbody>
          {selectedComponents.map((component, index) => {
            return (
              <tr key={`package-component-${index}`}>
                <td>{component.code}</td>
                <td>{component.name}</td>
                <td>{component.type}</td>
                <OptionalComponent showComponent={readOnly !== true}>
                  <td>
                    <Button onClick={() => onComponentRemove(index)} size='sm'>
                      <FaMinusCircle />
                    </Button>
                  </td>
                </OptionalComponent>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

PackageComponents.propTypes = {
  selectedComponents: PropTypes.array.isRequired,
  onComponentRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}

export default PackageComponents
