import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { login } from '../../services/authService'
import { setToken } from '../../utils/session'
import ErrorMessage from '../../components/system/ErrorMessage'

async function sendLogin (email, password) {
  if (!email || !password) {
    throw new Error('missing input')
  }
  const response = await login(email, password)
  const { token } = response.data
  if (!token) throw new Error('Internal server error')
  setToken(token)
}

const defaultState = {
  email: '',
  password: ''
}

const LoginView = () => {
  const [state, setState] = useState(defaultState)
  const { email, password, error } = state
  const showError = error !== undefined

  function handleLogin (e) {
    e.preventDefault()
    return sendLogin(email, password)
      .then(() => (window.location.href = '/'))
      .catch((error) => setState({ ...state, error: error.message }))
  }
  return (
    <div>
      <Container>
        <Modal
          show
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title id='contained-modal-title-vcenter'>
              ¡Por favor Iniciar sesión!
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleLogin}>
            <Modal.Body>
              <ErrorMessage showError={showError} error={error} />

              <Row key='email-input'>
                <Col>
                  <Form.Label>Correo</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='email'
                    value={email}
                    onChange={({ target: { value } }) =>
                      setState({ ...state, email: value })}
                  />
                </Col>
              </Row>
              <Row key='password-input'>
                <Col>
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='password'
                    value={password}
                    onChange={({ target: { value } }) =>
                      setState({ ...state, password: value })}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='success' type='submit'>
                Acceso!
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    </div>
  )
}

export default LoginView
