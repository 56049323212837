import React from 'react'

import UsersView from '../views/admin/UsersView'
import UserDetailsView from '../views/admin/UserDetailsView'
import AccountDetailsView from '../views/admin/AccountDetailsView'
import LogoutView from '../views/admin/LogoutView'

const routes = [
  {
    path: 'admin/users',
    element: <UsersView />,
    handle: {
      crumb: UsersView.breadcrumbs
    }
  },
  {
    path: 'admin/profile',
    element: <UserDetailsView />
  },
  {
    path: 'admin/account_details',
    element: <AccountDetailsView />
  },
  {
    path: 'admin/logout',
    element: <LogoutView />
  }
]

export default routes
