import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'

const Select = ({
  options = [],
  defaultValue,
  onChange,
  fieldName,
  disabled,
  noEmptyValue = false,
  property
}) => {
  const [state, setState] = useState({ value: defaultValue })
  const { value } = state
  return (
    <>
      <Form.Label>{fieldName}</Form.Label>
      <Form.Control
        as='select'
        value={value}
        disabled={disabled}
        onChange={({ target: { value } }) => {
          setState({ ...state, value })
          onChange(property, value)
        }}
      >
        {(noEmptyValue === false) && (<option value='' />)}
        {options.map((option) => {
          return (
            <option key={`option-${uuid()}`} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </Form.Control>
    </>
  )
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
  noEmptyValue: PropTypes.bool,
  property: PropTypes.string
}

export default Select
