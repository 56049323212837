import { parseField } from '../../components/common/CRUD/Field'
import { Link } from 'react-router-dom'
import React from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'
import { v4 } from 'uuid'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Código',
    property: 'code'
  }),
  parseField({
    name: 'Código de impuestos',
    property: 'taxCode',
    displayInTable: false
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  parseField({
    name: 'Descripción',
    property: 'description',
    type: 'textarea'
  })
]

export const extraActions = [
  {
    component: (entity) => {
      return (
        <span key={`custom-action-${v4()}`}>
          <Link
            to={`/packages/edit/${entity.id}`}
            className='btn btn-primary btn-sm'
          >
            <FaEdit />
          </Link>
          <Link
            to={`/packages/delete/${entity.id}`}
            className='btn btn-primary btn-sm ml-1'
          >
            <FaTrash />
          </Link>
        </span>
      )
    }
  }
]

export const getCatalogueActions = ({ onAddCallback }) => {
  const catalogueActions = [
    parseFieldAction(onAddCallback, 'FaPlusCircle', 'add')
  ]
  return catalogueActions
}
