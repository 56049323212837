import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from 'uuid'
import TagSchemaClassesService from '../../../services/tagSchemaClassService'
import PropTypes from 'prop-types'

const TagSchemaClassPicker = (params) => {
  const {
    onTransactionUpdate,
    transaction = {},
    creditClasses,
    required = true,
    disabled = false,
    initialSchemaClasses
  } = params

  let schema = null
  if (creditClasses !== null && creditClasses !== undefined) {
    schema = creditClasses ? 'CREDIT_TRANSACTIONS' : 'DEBIT_TRANSACTIONS'
  }

  const [state, setState] = useState({
    schemaClasses: initialSchemaClasses
  })
  const { schemaClasses } = state
  const hasSchemaClassesKeys = (classes = {}) =>
    Object.keys(classes).length > 0

  useEffect(() => {
    if (!initialSchemaClasses) {
      TagSchemaClassesService.getAllSchemaClasses(true, schema).then((schemaClasses) => {
        setState((s) => ({ ...s, schemaClasses }))
      })
    }
  }, [initialSchemaClasses, schema])

  let schemaClassesKeys = []
  if (hasSchemaClassesKeys(schemaClasses)) { schemaClassesKeys = Object.keys(schemaClasses) }

  const className = transaction.className || ''
  return (
    <Form.Control
      as='select'
      value={className}
      disabled={disabled}
      required={required}
      onChange={({ target: { value } }) => {
        onTransactionUpdate(transaction, 'className', value)
      }}
    >
      <option> </option>
      {schemaClassesKeys.map((schemaClass) => {
        return (
          <option key={`option-class-${uuid()}`} value={schemaClass}>
            {schemaClass}
          </option>
        )
      })}
    </Form.Control>
  )
}

TagSchemaClassPicker.propTypes = {
  initialSchemaClasses: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  creditClasses: PropTypes.bool
}

export default TagSchemaClassPicker
