import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductsCatalogue from './products/ProductsCatalogue'
import PackagesCatalogue from './packages/PackagesCatalogue'
import ServicesCatalogue from './services/ServicesCatalogue'
import PropTypes from 'prop-types'

const ProductsAndServicesCatalogue = (props) => {
  const { onAdd, showPackages = false } = props
  return (
    <>
      <Tabs defaultActiveKey='products'>
        <Tab eventKey='products' title='Productos'>
          <Container>
            <Row>
              <Col>
                <ProductsCatalogue onProductAdd={onAdd} />
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab eventKey='services' title='Servicios'>
          <Container>
            <Row>
              <Col>
                <ServicesCatalogue onServiceAdd={onAdd} />
              </Col>
            </Row>
          </Container>
        </Tab>
        {showPackages && (
          <Tab eventKey='packages' title='Packages'>
            <Container>
              <Row>
                <Col>
                  <PackagesCatalogue onPackageAdd={onAdd} />
                </Col>
              </Row>
            </Container>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

ProductsAndServicesCatalogue.propTypes = {
  onAdd: PropTypes.func.isRequired,
  showPackages: PropTypes.bool
}

export default ProductsAndServicesCatalogue
