import { getFieldControl } from './FieldControl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import PropTypes from 'prop-types'

export const EntityFields = (props) => {
  const { fields = [], isCreate, entity, onChange, readOnly, loaderData } = props
  let targetIndexForAutoFocus = 0
  return fields.map((field, index) => {
    let autoFocus = false
    if (index === targetIndexForAutoFocus) {
      const targetProp = isCreate === true ? 'insertDisabled' : 'editDisabled'
      if (field[targetProp] === true) {
        targetIndexForAutoFocus++
      } else {
        autoFocus = true
      }
    }
    return (
      <Row key={`modal-field-${index}`}>
        <Col>
          {getFieldControl(
            { field, isCreate, entity, onChange, readOnly, autoFocus, loaderData },
            index
          )}
        </Col>
      </Row>
    )
  })
}

EntityFields.propTypes = {
  fields: PropTypes.any,
  isCreate: PropTypes.any,
  entity: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.func,
  loaderData: PropTypes.any
}
