import React from 'react'
import CRUD from '../../components/common/CRUD/CRUD'
import { fields } from '../../models/sales/productModel'
import { createCRUDService } from '../../services/crudService'
import { Breadcrumb } from 'react-bootstrap'

const ProductsView = () => {
  const { listItems, deleteItem, createItem, updateItem } = createCRUDService(
    '/api/sales/products'
  )
  return (
    <main style={{ padding: '1rem 0' }}>
      <CRUD
        fields={fields}
        paginated
        entityName='producto'
        entityNamePlural='productos'
        dataSource={listItems}
        onDelete={deleteItem}
        onCreate={createItem}
        onUpdate={updateItem}
      />
    </main>
  )
}

ProductsView.breadcrumbs = () => (
  <Breadcrumb.Item key='breadcrumb-products' href='/products' active>
    Productos
  </Breadcrumb.Item>
)

export default ProductsView
