import { parseField } from '../../components/common/CRUD/Field'
import { parseFieldAction } from '../../components/common/CRUD/FieldAction'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Nombre',
    property: 'name'
  }),
  parseField({
    name: 'Descripción',
    property: 'description',
    type: 'textarea'
  })
]

export const getExtraActions = (companyId) => {
  return [
    parseFieldAction(
      (entity) => {
        window.location.href = `/companies/${companyId}/inventories/${entity.id}/stock`
      },
      'FaListOl',
      'Inventory Stock'
    )
  ]
}
