import { getAxios } from '../utils/requests'
const axios = getAxios()

export const findItem = (code) => {
  return axios.get(`/api/material_resources/items/code/${code}`).then((response) => response.data)
}

export const sendInventoryUpdate = (inventoryId, entries) => {
  return axios.put(
    `/api/material_resources/inventories/${inventoryId}/items`,
    entries
  )
}

export const getInventoryStock = async (companyId, inventoryId) => {
  const response = await axios.get(
    `/api/material_resources/inventories/${inventoryId}/items`
  )
  return response.data
}
