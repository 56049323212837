import { get } from 'lodash'
import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import PropTypes from 'prop-types'
import QuickTransaction from '../transactions/QuickTransaction'

const TransactionsTable = (props) => {
  const { sortBy, transactions, onQuickTransactionSave, isCreditCard } = props
  const [state, setState] = useState({
    showTransactionModal: false
  })
  const closeModal = () => setState((s) => ({ ...s, showTransactionModal: false }))
  const handleSave = () => {
    closeModal()
    onQuickTransactionSave()
  }
  const showModal = (transaction) => {
    setState((s) => ({ ...s, showTransactionModal: true, transactionToUpdate: transaction }))
  }
  const { showTransactionModal, transactionToUpdate } = state
  return (
    <>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr id='table-headers'>
            <th>ID</th>
            <th>Cuenta</th>
            <th onClick={() => sortBy('date')}>Fecha</th>
            <th>Descripción</th>
            <th onClick={() => sortBy('amount')}>Monto</th>
            <th>Etiqueta</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => {
            return (
              <tr key={`transaction-${index}`} onClick={() => showModal(transaction)}>
                <td>{transaction.id}</td>
                <td>
                  {transaction.bankAccount.bank}{' '}
                  {transaction.bankAccount.description}{' '}
                  {transaction.bankAccount.number}{' '}
                </td>
                <td>{transaction.date}</td>
                <td>{transaction.description}</td>
                <td>{transaction.amount}</td>
                <td>{get(transaction, 'classes[0].name', 'Sin Clasificar')}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {(showTransactionModal === true) && (
        <QuickTransaction
          showQuickTransaction={showTransactionModal}
          toggleModal={closeModal}
          onSave={handleSave}
          transaction={transactionToUpdate}
          update
          isCreditCard={isCreditCard}
        />
      )}
    </>
  )
}

TransactionsTable.propTypes = {
  sortBy: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      bankAccount: PropTypes.shape({
        bank: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired
      })
    })
  ).isRequired,
  onQuickTransactionSave: PropTypes.func,
  isCreditCard: PropTypes.bool
}

export default TransactionsTable
