import React from 'react'
import MenusView from '../views/restaurant/MenusView'
import MenuItemsView from '../views/restaurant/MenusItemsView'
import OrdersView from '../views/restaurant/OrdersView'
import TablesView from '../views/restaurant/TablesView'

const routes = [
  {
    path: 'restaurants/menus',
    element: <MenusView />,
    handle: {
      crumb: MenusView.breadcrumbs
    }
  },
  {
    path: 'restaurants/menus/:id/items',
    element: <MenuItemsView />,
    handle: {
      crumb: MenuItemsView.breadcrumbs
    }
  },
  {
    path: 'restaurants/orders',
    element: <OrdersView />,
    handle: {
      crumb: OrdersView.breadcrumbs
    }
  },
  {
    path: 'restaurants/tables',
    element: <TablesView />,
    handle: {
      crumb: TablesView.breadcrumbs
    }
  }
]

export default routes
