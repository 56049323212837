import { parseField } from '../../components/common/CRUD/Field'

export const fields = [
  parseField({
    name: 'id',
    type: 'number',
    insertDisabled: true,
    editDisabled: true
  }),
  parseField({
    name: 'Nombre',
    property: 'firstName'
  }),
  parseField({
    name: 'Apellido',
    property: 'lastName'
  }),
  parseField({
    name: 'Correo',
    property: 'email',
    type: 'email',
    editDisabled: true
  }),
  parseField({
    name: 'Contraseña',
    property: 'password',
    type: 'password',
    editDisabled: true,
    displayInTable: false
  })
]
