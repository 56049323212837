import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ProjectDetails from './ProjectDetails'

const NewProjectModal = (props) => {
  const { show, onCancel, onSubmit, newProject, onProjectUpdate } = props
  const { name, clientId } = newProject
  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Nuevo Proyecto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectDetails
            name={name}
            clientId={clientId}
            handleProjectUpdate={onProjectUpdate}
            disableClientPicker={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onSubmit(newProject)} variant='primary'>
            Guardar
          </Button>
          <Button onClick={onCancel} variant='secondary'>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

NewProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  newProject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onProjectUpdate: PropTypes.func.isRequired
}

export default NewProjectModal
