import React from 'react'
import PropTypes from 'prop-types'
import { fields } from '../../../models/restaurants/menuItemModel'
import CRUD from '../../common/CRUD/CRUD'
import { createCRUDService } from '../../../services/crudService'

const MenuItems = (props) => {
  const { items, menuId, onItemChanges } = props
  const { deleteItem, createItem, updateItem } = createCRUDService(
    `/api/restaurants/menus/${menuId}/items`
  )
  return (
    <CRUD
      fields={fields}
      paginated
      entityName='producto o platillo'
      entityNamePlural='Productos y Platillos'
      showAllInList
      dataSource={() => Promise.resolve({ rows: items })}
      onDelete={(item) => deleteItem(item).then(onItemChanges)}
      onCreate={(item) => createItem(item).then(onItemChanges)}
      onUpdate={(item) => updateItem(item).then(onItemChanges)}
    />
  )
}

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired
    })
  ),
  menuId: PropTypes.string.isRequired,
  onItemChanges: PropTypes.func.isRequired
}

export default MenuItems
